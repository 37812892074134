import React from 'react';
import $ from 'jquery';
import Branding from '../components/icons/branding';
import Cube from '../components/icons/cube';
import Web from '../components/icons/web';


class ButtomService extends React.Component {

  componentDidMount() {
		setTimeout(() => { this.addAnimation('.service1') }, 200);
		setTimeout(() => { this.addAnimation('.service2') }, 600);
		setTimeout(() => { this.addAnimation('.service4') }, 1000);

    this.GoSection('.service1', '.point-web');
    this.GoSection('.service2', '.point-animation-3d');
    this.GoSection('.service4', '.point-branding');

    this.hoverServices('.service1', '.slide-web');
    this.hoverServices('.service2', '.slide-animation');
    this.hoverServices('.service3', '.slide-concept');
    this.hoverServices('.service4', '.slide-branding');
	}

  /*-------------------------------------------------------------------------------*/
	// show icons serveices
	/*-------------------------------------------------------------------------------*/

	addAnimation(object) {
		$(object).addClass('animation-iso');
	}

  GoSection(btn, section) {
		if ($(section).length) {
			let hHeader = 60;
			let scrollTopSection = $(section).offset().top;
			$(btn).click(function () {
				$('html, body').animate({ scrollTop: (scrollTopSection - hHeader) }, 'fast');
			});
		}
	}

  /*-------------------------------------------------------------------------------*/
  // Hover Service
  /*-------------------------------------------------------------------------------*/

  hoverServices(btn, service){
    $(btn).mouseenter(function(){
      $(service).addClass('enabled-slide');
      $(service).removeClass('disable-slide');
      $('.slider-wrapper').removeClass('state-enabled');
    });
    $(btn).mouseleave(function(){
      $(service).addClass('disable-slide');
      $('.slider-wrapper').addClass('state-enabled');
    });
  }

  
  render() {
    return (
      <>
        <div className={`service-btn service${this.props.serviceNumber} ${this.props.service == "3d" ? "cube" : this.props.service} col d-flex justify-content-center pt-4 pb-4`}>
          <div className="d-flex flex-nowrap justify-content-center align-items-center">
            <div className="iso-services col-auto">
              {this.props.service === "3d" && <Cube />}
              {this.props.service === "branding" && <Branding />}
              {this.props.service === "web" && <Web />}
            </div>
            <div className="col">
              <div className="title-services">{this.props.title}</div>
              <div className="btn-text">
                Descubrir Más
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ButtomService;