import React from "react";
import $ from "jquery";
import { Waypoint } from "react-waypoint";
import { Link } from "react-router-dom";
import imageService1 from "../assets/images/sirio-3D-step1.webp";
import imageService2 from "../assets/images/sirio-3D-step2.webp";
import imageService3 from "../assets/images/sirio-3D-step3.webp";
import imageWebMobile from "../assets/images/web-mobile.webp";
import imageWebService from "../assets/images/web-service.webp";
import imageWebResponsive from "../assets/images/web-responsive.webp";
import Cube from "./icons/cube";
import Wire from "./icons/wire";
import Art from "./icons/art";
import Web from "./icons/web";
import Branding from "./icons/branding";
import ArtVideo from "../assets/video/concept-art.mp4";

class Service extends React.Component {
  componentDidMount() {}

  _setClass(targetClass, pointClass) {
    $(targetClass).addClass(pointClass);

    if ($(`.point-${this.props.classPoint}`).hasClass("content-video")) {
      let mediaElement = document.getElementById(
        `.point-${this.props.classPoint}`
      );
      mediaElement.onended = function () {
        mediaElement.currentTime = 12;
        mediaElement.play();
      };
      mediaElement.play();
    }
  }

  render() {
    const classServiceCommons =
      "col-12 d-flex flex-column align-items-center justify-content-end col-md-6 picture-services";

    return (
      <>
        <div>
          <Waypoint
            bottomOffset="10%"
            onEnter={this._setClass.bind(
              this,
              `.point-${this.props.classPoint}`,
              " Animation"
            )}
            onLeave={this._setClass.bind(
              this,
              `.point-${this.props.classPoint}`,
              ""
            )}
          />
        </div>

        <article
          className={`row mb-3 mb-md-4 mb-lg-5 point-${this.props.classPoint} ${
            this.props.classPoint === "concept-art" ? "content-video" : ""
          }`}
        >
          <div
            className={`col-12 col-md-6 d-flex flex-row align-items-center info-service ${
              this.props.invert ? "order-2 order-md-2" : "order-2 order-md-1"
            }`}
          >
            <div className="row justify-content-center">
              <div className="col-9">
                {this.props.classPoint === "animation-3d" && <Cube />}
                {this.props.classPoint === "concept-art" && <Art />}
                {this.props.classPoint === "web" && <Web />}
                {this.props.classPoint === "branding" && <Branding />}
              </div>
              <div className="col-9 title-service">
                <h2>{this.props.titleService}</h2>
              </div>

              <div className="col-9 text-service">
                <p>{this.props.descriptionService}</p>
              </div>

              <div className="col-9 box-btn mt-3">
                <div className="d-flex justify-content-center">
                  <a className="buttom btn-quote" rel={this.props.classPoint}>
                    Cotiza tu Proyecto
                  </a>
                </div>

                <div className="mt-4 | d-flex justify-content-center">
                  <Link className="scale-tn2 | quiet" to="projects">
                    {this.props.textLinkService}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Link
            to={this.props.link}
            className={`not-decoration ${classServiceCommons} ${
              this.props.service
            }-service ${
              this.props.service === "concept-art" || "branding" ? "p-0" : ""
            } ${
              this.props.invert ? "order-1 order-md-1" : "order-1 order-md-2"
            }`}
          >
            {this.props.classPoint === "animation-3d" && (
              <div className="sirio-picture-3d">
                <img className="capa-3" src={imageService1} alt="3D Design" />
                <Wire />
                <img className="capa-2" src={imageService2} alt="3D Design" />
                <img className="capa-1" src={imageService3} alt="3D Design" />
              </div>
            )}

            {this.props.classPoint === "concept-art" && (
              <div className="sirio-concept-art">
                {!this.props.mobile && (
                  <video
                    className="d-none d-lg-block"
                    id={`.point-${this.props.classPoint}`}
                    preload="auto"
                    poster="../assets/images/concept-art.webp"
                  >
                    <source src={ArtVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            )}

            {this.props.classPoint === "web" && (
              <div className="d-flex align-items-end justify-content-center position-relative wrapper-web-design mb-4 mb-md-0">
                <div className="sirio-web-mobile">
                  <img src={imageWebMobile} alt="Web Design" />
                </div>
                <div className="sirio-web-design">
                  <img src={imageWebService} alt="Web Design" />
                </div>
                <div className="sirio-web-responsive">
                  <img src={imageWebResponsive} alt="Web Design" />
                </div>
              </div>
            )}

            {this.props.classPoint === "branding" && (
              <div className="sirio-branding"></div>
            )}

            <div
              className="d-flex w-100 align-items-start info-project gap-2"
              dangerouslySetInnerHTML={{ __html: this.props.categoryService }}
            ></div>
          </Link>
        </article>
      </>
    );
  }
}

export default Service;
