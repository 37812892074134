import React from 'react'
import SliderInside from '../components/slider_inside'
import OtherProjects from '../components/other_projects'
import BoxProject from '../components/box_project'

// Assets
import logo from '../assets/images/projects/cafe-de-nohelia/logo.webp'
import logoLine from '../assets/images/projects/cafe-de-nohelia/logo-inline.webp'
import isoLogo from '../assets/images/projects/cafe-de-nohelia/iso-logo.webp'
import Render from '../assets/images/projects/cafe-de-nohelia/3D-model.webp'


class Nohelia extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<>
				<SliderInside
					service="branding"
					project="nohelia"
					name="CAFÉ DE NOHELIA"
					description="<b class='badge badge--branding'>Marca</b>"
				/>

				<section className="container-fluid bg-overlay-site position-relative z2">
					<div className="row justify-content-center services-gallery">

						<BoxProject
							elementType="image"
							elementSrc={logo}
							title="BRANDING"
							description="Branding, Diseño de Empaque, Manual de imagen."
							layout="col-12 mb-4 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={logoLine}
							title="BRANDING"
							description="Versión en linea"
							layout="col-12 col-lg-6 none-info mb-3 mb-md-4 mb-lg-0"
						/>

						<BoxProject
							elementType="image"
							elementSrc={isoLogo}
							title="BRANDING"
							description="Logotipo"
							layout="col-12 col-lg-6 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={Render}
							title="BRANDING"
							description="Aplicaciones"
							layout="col-12 mt-4 mb-4 none-info"
						/>
					</div>

					<div className="col-12">
						<div className="d-flex justify-content-center box-btn mt-4 pb-3 pb-md-4">
							<a className="buttom btn-quote" rel="model">Cotiza tu Proyecto</a>
						</div>
					</div>

					<OtherProjects
						type='branding'
						noPrint='nohelia'
					/>
				</section>
			</>
		);
	}

}

export default Nohelia;