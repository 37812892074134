import React from 'react'
import $ from 'jquery'
import Quote from './quote';
import imageQR from '../assets/images/SVG/qr-code.svg'
import Isologo from './isologo';

class ModalQuote extends React.Component {

	render() {
		return (
			<div className="pop-quote d-flex align-items-center justify-content-center">
				<div className="content-quote">
					<div className="row h-100 m-0">
						<div className="col-md-12 col-lg-5 p-0 d-none d-md-block">
							<div className="col-12 p-0 d-block d-lg-none">
								<div className="buttom close row align-items-center justify-content-center">
									<div className="line-top"></div>
									<div className="line-bottom"></div>
								</div>
							</div>
							<div className="image-pop-quote"></div>
						</div>
						<div className="col-md-12 col-lg-7">
							<div className="row align-items-start  align-items-md-center align-items-lg-start h-100">
								<div className="col-12 p-0 d-block d-md-none d-lg-block content-buttom-close">
									<div className="buttom close row align-items-center justify-content-center">
										<div className="line-top"></div>
										<div className="line-bottom"></div>
									</div>
								</div>
								<div className="col-11 col-xl-10 row align-items-center content-info-pop ml-auto mr-auto">
									<div className="w-100">
										<div className="mb-4">
											<Isologo customClass="large" />
										</div>
										<div className="row mb-0 mb-md-4 pb-4">
											<div className="col-12 content-phone mb-2">
												<div className="phone">
													<b className='text-secundary scale-tn1'>¿Deseas hacer realidad tu proyecto?</b>
													<b className="get-in-touch scale-t2 mb-4 d-block">
														¡Ponte en contacto!
													</b>
													
													<a href='https://wa.me/573132572108' target={ '_blank' } className='my-4 gap-3 | not-decoration | d-flex align-items-center'>
														<img className='d-none d-md-block' width={130} src={imageQR} alt="Whatsapp" />
														<div className='d-grid'>
															<span className='scale-t2 | text-white | d-block'><i className='fab fa-whatsapp d-md-none'></i> (+57) 313.257.2108</span>
															<span className='scale-tn2'>Llamanos o Agréganos en WhatsApp</span>
														</div>
													</a>
												</div>
											</div>
											<div className="col-12 contact-tips">
												<div className="d-flex mb-3 scale-t1">
													<a className='text-secondary' href="mailto:siriostudiosdesign@gmail.com">siriostudiosdesign@gmail.com</a>
												</div>
												<div className="social-media d-flex scale-t2 gap-5">
													<a target="_blank" href="https://www.behance.net/siriostudiosdesign/">
														<i className="fab fa-behance"></i>
													</a>
													<a target="_blank" href="https://www.artstation.com/siriostudiosdesign/">
														<i className="fab fa-artstation"></i>
													</a>
													<a target="_blank" href="https://www.facebook.com/siriostudiosdesign/">
														<i className="fab fa-facebook"></i>
													</a>
													<a target="_blank" href="https://www.instagram.com/siriostudiosdesign/">
														<i className="fab fa-instagram"></i>
													</a>
												</div>
											</div>
										</div>


										{/*
										<h3 className="title mb-2">¡Hagamos realidad tu proyecto!</h3> 
										<div className="form">
											<Quote
												suffix = '-pop'
											/>
										</div>
										*/}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ModalQuote;

