import React from 'react'
import { Link } from 'react-router-dom'
import Cube from '../components/icons/cube'
import SliderInside from '../components/slider_inside'
import OtherProjects from '../components/other_projects'

// Assets
import Image1 from '../assets/images/projects/million/diseno-web-001.webp'
import Image2 from '../assets/images/projects/million/diseno-web-002.webp'
import Image3 from '../assets/images/projects/million/diseno-web-003.webp'
import Image4 from '../assets/images/projects/million/diseno-web-004.webp'
import Image5 from '../assets/images/projects/million/diseno-web-005.webp'
import Image6 from '../assets/images/projects/million/diseno-web-006.webp'



class Hontus extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	
	render() {
		return (
			<>

			</>
		);
	}

}

export default Hontus;