import React from 'react';
import Copyright from '../components/copyright'
import Isologo from './isologo';

class BoxProject extends React.Component {
  render() {
    return(
      <>
        <div className={`box-image-information ${this.props.layout}`}>

          {this.props.title &&
            <div className="position-absolute row z3 w-100 pl-3 pr-3 d-none d-md-flex">
              <div className="m-3 ml-auto copy">
                <Copyright />
              </div>
            </div>
          }

          <div className="image">
            {this.props.elementType === "image" && 
              <img className="w-100" src={this.props.elementSrc} />
            }
            {this.props.elementType === "video" && 
              <video preload="auto" autoPlay loop>
                <source src={this.props.elementSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            }
          </div>
          
          {this.props.title &&
            <div className="information d-flex align-items-center p-3">
              <Isologo customClass="small mr-3 color-plata" />
              <div className='flex-grow-1 text-secondary'>
                <p className="mb-0 title">{this.props.title} <span className='d-inline-block px-1'>|</span> {this.props.description}</p>
              </div>
            </div>
          }

        </div>
      </>
    );
  }
}

export default BoxProject;
