
import React from 'react';

class Web extends React.Component {
  render() {
    return (
      <svg className="web" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.79 50.84">
        <g data-name="Capa 2">
          <g data-name="Capa 1">
            <rect className="cls-1 s1" x="0.5" y="0.5" width="57.79" height="38.37" rx="5" ry="5" />
            <line className="cls-1 s2" x1="0.5" y1="30.02" x2="58.29" y2="30.02" />
            <line className="cls-1 s2" x1="16.18" y1="50.34" x2="42.61" y2="50.34" />
            <polygon className="cls-1 s2" points="36.52 50.34 22.27 50.34 24.11 38.87 34.67 38.87 36.52 50.34" />
            <polygon className="cls-1 s3" points="10.7 11.24 13.26 15.74 13.73 13.49 15.98 12.54 10.7 11.24" />
            <line className="cls-1 s3" x1="13.73" y1="13.49" x2="17.36" y2="16.21" />
          </g>
        </g>
      </svg>
    );
  }

}

export default Web;