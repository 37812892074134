import React from 'react';

class Copyright extends React.Component {
  render() {
    return(
      <>
        Sirio Studios © Copyright 2015 - 2021.
      </>
    );
  }
}

export default Copyright;
