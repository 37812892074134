import React from 'react';
import $ from 'jquery';
import { Waypoint } from 'react-waypoint';
import BoxClient from './box_client';
import miles from '../assets/images/SVG/miles.svg'
import million from '../assets/images/SVG/million.svg'
import viajemos from '../assets/images/SVG/viajemos.svg'
import energezee from '../assets/images/SVG/energezee.svg'
import fendi from '../assets/images/SVG/fendi-chateau.svg'
import andrea from '../assets/images/SVG/andrea-lopez.svg'
import nohelia from '../assets/images/SVG/nohelia.svg'
import protegemos from '../assets/images/SVG/protegemos-seguridad.svg'

class Clients extends React.Component {
  componentDidMount() {
		this.GoSectionHash();
	}

  _setClass(targetClass, pointClass) {
		$(targetClass).addClass(pointClass);
	}

  /*-------------------------------------------------------------------------------*/
	// Go Section Animation
	/*-------------------------------------------------------------------------------*/

	GoSectionHash(){
		let URLhash = window.location.hash;
		let hHeader = 60;
		if(URLhash){
			let scrollTopSection = $(URLhash).offset().top;
			$('html, body').animate({scrollTop:(scrollTopSection - hHeader)}, 'fast');
		}
	}
  
  render() {
    return(
      <>
        <div>
          <Waypoint
            bottomOffset="50%"
            onEnter={this._setClass.bind(this, `.point-${this.props.classPoint}`, ' Animation')}
            onLeave={this._setClass.bind(this, `.point-${this.props.classPoint}`, '')}
          />
        </div>

        <section id="clients" className={`container-fluid bg-overlay-site clients pb-3 pb-md-4 pb-lg-5 pt-3 pt-md-4 pt-lg-5 z2 point-${this.props.classPoint}`}>
          <div className="row justify-content-center mb-4">
            <h3>Nuestros Clientes</h3>
          </div>
          <article className="row justify-content-center">
            <BoxClient
              image = {miles}
              description = "Diseño UI/UX, Diseño de Marca."
              link = "miles-car-rental"
              customClass = "miles"
            />
            <BoxClient
              image = {million}
              description = "Diseño UI/UX, Diseño de Marca, Manual de imagen."
              link = "million-and-up"
              customClass = "million"
            />
            <BoxClient
              image = {viajemos}
              description = "Diseño UI/UX, Diseño de Marca, Manual de imagen."
              link = "viajemos"
              customClass = "viajemos"
            />
            <BoxClient
              image = {protegemos}
              description = "Diseño UI/UX, Diseño de Marca, Manual de imagen."
              link = "protegemos-seguridad"
              customClass = "protegemos"
            />
            <BoxClient
              image = {energezee}
              description = "Diseño UI/UX."
              link = "#"
              customClass = "energezee"
            />
            <BoxClient
              image = {fendi}
              description = "Diseño UI/UX."
              link = "fendi-chateau"
              customClass = "fendi"
            />
            <BoxClient
              image = {andrea}
              description = "Diseño UI/UX, Diseño de Marca, Manual de imagen."
              link = "#"
              customClass = "andrea"
            />
            <BoxClient
              image = {nohelia}
              description = "Diseño de Marca, Manual de imagen."
              link = "cafe-de-nohelia"
              customClass = "nohelia"
            />
            
          </article>
        </section>
      </>
    );
  }
}

export default Clients;
