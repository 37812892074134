import React from "react";
import $ from "jquery";
import Service from "../components/services";
import Clients from "../components/clients";
import ButtomService from "../components/buttom_services";
import posterIntro from "../assets/images/slide.webp";
import videoIntro from "../assets/video/sirio.mp4";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mobile: true };
  }

  componentDidMount() {
    this.GoSectionHash();
    window.scrollTo(0, 0);
    $("body").addClass("home");

    if ($(document).width() < 769) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }
  }

  /*-------------------------------------------------------------------------------*/
  // Loop Video
  /*-------------------------------------------------------------------------------*/

  loopVideo() {
    let videoSlide = document.getElementById("sirio-video");
    videoSlide.currentTime = 5.3;
    videoSlide.play();
  }

  /*-------------------------------------------------------------------------------*/
  // Go Section Animation
  /*-------------------------------------------------------------------------------*/

  GoSectionHash() {
    let URLhash = window.location.hash;
    let hHeader = 60;
    if (URLhash) {
      let scrollTopSection = $(URLhash).offset().top;
      $("html, body").animate(
        { scrollTop: scrollTopSection - hHeader },
        "fast"
      );
    }
  }

  render() {
    const mobile = this.state.mobile;

    return (
      <>
        <div className="slider-wrapper slide-home | m-0 | hover-slide">
          <div className="container-fluid d-flex align-items-end align-items-lg-center">
            <div className="row">
              <div className="work-form col-lg-9 col-xl-8 z5 mt-5 pt-5">
                <h1 className="title | scale-t2 scale-md-t5 scale-lg-t8 scale-xxl-t10 | mb-0">
                  Sirio ofrece diseño de alta gama con las mejores prácticas de{" "}
                  <b className="text-primary">
                    UX UI, Design Systems & Animación 3D.
                  </b>
                </h1>
              </div>
            </div>
          </div>

          <div id="services" className="services-icons | align-self-end | my-3">
            <div className="container-fluid">
              <div className="row d-flex flex-nowrap navigation-services align-items-center gap-4">
                <ButtomService
                  service="web"
                  title="Diseño UX UI"
                  serviceNumber="1"
                />

                <ButtomService
                  service="branding"
                  title="Diseño de Marca"
                  serviceNumber="4"
                />

                <ButtomService
                  service="3d"
                  title="Diseño 3D"
                  serviceNumber="2"
                />

                {/* <ButtomService 
									service = "art"
									title = "ARTE CONCEPTUAL"
									serviceNumber = "3"
								/> */}
              </div>
            </div>
          </div>

          <div className="slide-video">
            <div className="slide patern-video"></div>
            {!mobile && (
              <>
                <div className="slide-web slide"></div>
                <div className="slide-animation slide"></div>
                <div className="slide-concept slide"></div>
                <div className="slide-branding slide"></div>
                <video
                  className="d-none d-lg-block"
                  id="sirio-video"
                  onEnded={this.loopVideo}
                  preload="auto"
                  poster={posterIntro}
                  autoPlay
                >
                  <source src={videoIntro} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </>
            )}
          </div>
        </div>

        <section className="container-fluid home bg-overlay-site position-relative z2 overflow-hidden pt-5">
          <Service
            service="web"
            classPoint="web"
            titleService="Diseño UX UI"
            descriptionService="Nuestro equipo UX/UI trabajan arduamente para realizar sitios amigables con el usuario generándole emociones positivas haciendo que en definitiva sea una buena experiencia."
            textLinkService="VER PROYECTOS"
            categoryService="<b class='badge badge--web'>UX UI</b> <b class='badge badge--layout'>Maquetación</b> <b class='badge badge--system'>Sistema de Diseño</b>"
            link="fendi-chateau"
            invert={false}
          />

          <Service
            service="branding"
            classPoint="branding"
            titleService="Diseño de Marca"
            descriptionService="Trabajamos con disciplina para encontrar la esencia de su marca y hacer que esta cobre vida para que sea distintiva, completa y relevante en el mercado."
            textLinkService="VER PROYECTOS"
            categoryService="<b class='badge badge--branding'>Branding</b> <b class='badge badge--web'>UX UI</b>"
            link="cafe-de-nohelia"
            invert={true}
          />

          <Service
            service="cube"
            classPoint="animation-3d"
            titleService="Diseño 3D"
            descriptionService="Nuestro equipo de creativos dará vida a tus proyectos tridimensionales. Ya sea para crear un personaje, escenarios o productos entendemos que cada uno tiene una historia y nos hemos comprometido a asegurarnos que cada historia sea increíble."
            textLinkService="VER PROYECTOS 3D"
            categoryService="<b class='badge badge--concept'>Concept Art</b> <b class='badge badge--3d'>3D Model</b> <b class='badge badge--animation'>3D Animation</b>"
            link="wake-up"
          />

          <Service
            service="concept-art"
            classPoint="concept-art"
            titleService="Arte Conceptual"
            descriptionService="Nuestro equipo de Diseñadores artísticos tiene la capacidad de crear el look and feel de su proyecto, desde películas de animación a videojuegos dándole una dirección visual épica ¡Hagámoslo realidad!"
            textLinkService="VER PROYECTOS"
            categoryService="<b class='badge badge--concept'>ART key</b> <b class='badge badge--3d'>Character</b>"
            invert={true}
            mobile={mobile}
            link="/"
          />
        </section>

        <Clients classPoint="clients" />
      </>
    );
  }
}

export default Home;
