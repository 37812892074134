import React from "react";
import SliderInside from "../components/slider_inside";
import OtherProjects from "../components/other_projects";
import BoxProject from "../components/box_project";

// Assets
import Image1 from "../assets/images/projects/znetworks/logo-design-znet-1.webp";
import Image2 from "../assets/images/projects/znetworks/logo-design-znet-2.webp";
import Image3 from "../assets/images/projects/znetworks/logo-design-znet-3.webp";
import Image4 from "../assets/images/znetworks.webp";

class Hontus extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <SliderInside
          service="3d"
          project="znetworks"
          name="ZNETWORKS"
          description="<b class='badge badge--branding'>Marca</b>"
        />

        <section className="container-fluid bg-overlay-site position-relative z2">
          <div className="row justify-content-center services-gallery">
            <BoxProject
              elementType="image"
              elementSrc={Image1}
              title="LOGO 3D"
              description="Diseño del isologo - V1"
              layout="col-12 col-lg-4 mb-3 mb-md-4 mb-lg-0 none-info"
            />

            <BoxProject
              elementType="image"
              elementSrc={Image2}
              title="LOGO 3D"
              description="Diseño del isologo - V2"
              layout="col-12 col-lg-4 mb-3 mb-md-4 mb-lg-0 none-info"
            />

            <BoxProject
              elementType="image"
              elementSrc={Image3}
              title="LOGO 3D"
              description="Diseño del isologo - V3"
              layout="col-12 col-lg-4 mb-3 mb-md-4 mb-lg-0 none-info"
            />

            <BoxProject
              elementType="image"
              elementSrc={Image4}
              title="RENDER FINAL"
              description="Aviso publicitario"
              layout="col-12 mt-4 mb-4 none-info"
            />
          </div>

          <div className="col-12">
            <div className="d-flex justify-content-center box-btn mt-4 pb-3 pb-md-4">
              <a className="buttom btn-quote" rel="model">
                Cotiza tu Proyecto
              </a>
            </div>
          </div>

          <OtherProjects type="branding" noPrint="znet" />
        </section>
      </>
    );
  }
}

export default Hontus;
