import React from 'react'
import SliderInside from '../components/slider_inside'
import BoxProject from '../components/box_project'
import OtherProjects from '../components/other_projects'

// Assets
import wireMino from '../assets/images/wire-mino.webp'
import render from '../assets/images/slide.webp'
import rigFace from '../assets/video/rig-face.mp4'
import rig from '../assets/video/rig.mp4'


class WakeUp extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <>
        <SliderInside
          service="3d"
          project="despertar"
          name="El DESPERTAR"
          description="
            <b class='badge badge--concept'>Concept Art</b>
            <b class='badge badge--3d'>3D</b>
            <b class='badge badge--animation'>Animacion</b>
          "
        />

        <section className="container-fluid bg-overlay-site position-relative z2">
          <div className="row justify-content-center services-gallery">
            <BoxProject
              elementType="image"
              elementSrc={wireMino}
              title="MODELADO 3D"
              description="Personaje high poly"
              layout="col-12 mb-4"
            />

            <BoxProject
              elementType="video"
              elementSrc={rigFace}
              title="RIG FACIAL"
              description="Personaje high poly"
              layout="col-12 col-lg-6 mb-3 mb-md-4 mb-lg-0"
            />

            <BoxProject
              elementType="video"
              elementSrc={rig}
              title="RIG CORPORAL"
              description="Personaje high poly"
              layout="col-12 col-lg-6"
            />

            <BoxProject
              elementType="image"
              elementSrc={render}
              title="RENDER FINAL"
              description="Personaje high poly"
              layout="col-12 mt-4 mb-4"
            />
          </div>

          <div className="col-12">
            <div className="d-flex justify-content-center box-btn mt-4 pb-3 pb-md-4">
              <a className="buttom btn-quote" rel="model">Cotiza tu Proyecto</a>
            </div>
          </div>

          <OtherProjects
            type='3d'
            noPrint='mino'
          />

        </section>
      </>
    );
  }
}

export default WakeUp;