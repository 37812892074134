import React from 'react'
import SliderInside from '../components/slider_inside'
import OtherProjects from '../components/other_projects'
import BoxProject from '../components/box_project'

// Assets
import viajemosImage1 from '../assets/images/projects/viajemos/viajemos_01.webp'
import viajemosImage3 from '../assets/images/projects/viajemos/viajemos_03.webp'
import viajemosImage4 from '../assets/images/projects/viajemos/viajemos_04.webp'
import viajemosImage5 from '../assets/images/projects/viajemos/viajemos_05.webp'
import viajemosImage6 from '../assets/images/projects/viajemos/viajemos_06.webp'
import viajemosImage7 from '../assets/images/projects/viajemos/viajemos_07.webp'


class Viajemos extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<>
				<SliderInside
					service="web"
					project="viajemos"
					name="Viajemos.com"
					description="
					<b class='badge badge--web'>UX UI</b>
					<b class='badge badge--branding'>Marca</b>
					<b class='badge badge--system'>Design System</b>
					<b class='badge badge--layout'>Maquetación</b>
					"
				/>

				<section className="container-fluid bg-overlay-site position-relative z2">
					<div className="row justify-content-center services-gallery">
						<BoxProject
							elementType="image"
							elementSrc={viajemosImage1}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={viajemosImage3}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={viajemosImage4}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={viajemosImage5}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={viajemosImage6}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={viajemosImage7}
							layout="col-12 none-info"
						/>
					</div>

					<div className="col-12">
						<div className="d-flex justify-content-center box-btn mt-4 pb-3 pb-md-4">
							<a className="buttom btn-quote" rel="model">Cotiza tu Proyecto</a>
						</div>
					</div>

					<OtherProjects
						type='web'
						noPrint='viajemos'
					/>

				</section>
			</>
		);
	}
}

export default Viajemos;