
import React from 'react';

class Cube extends React.Component {
  render() {
    return (
      <svg className="cube" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.88 59.9">
        <g data-name="Capa 2">
          <g data-name="Capa 1">
            <polygon className="cls-1 s1" points="28.44 2.25 54.63 15.62 28.44 29 2.25 15.62 28.44 2.25" />
            <polygon className="cls-1 s2" points="28.44 36.45 49.21 47.05 28.44 57.66 7.67 47.05 28.44 36.45" />
            <line className="cls-1 s3" x1="28.44" y1="29" x2="28.44" y2="57.66" />
            <line className="cls-1 s3" x1="54.63" y1="15.62" x2="49.21" y2="47.05" />
            <line className="cls-1 s3" x1="2.25" y1="15.62" x2="7.67" y2="47.05" />

            <circle className="cls-2 p1" cx="2.25" cy="15.62" r="2.25" />
            <circle className="cls-2 p2" cx="28.44" cy="2.25" r="2.25" />
            <circle className="cls-2 p3" cx="54.63" cy="15.62" r="2.25" />
            <circle className="cls-2 p4" cx="49.21" cy="47.05" r="2.25" />
            <circle className="cls-2 p5" cx="28.44" cy="57.66" r="2.25" />
            <circle className="cls-2 p6" cx="7.67" cy="47.05" r="2.25" />
            <circle className="cls-2 p7" cx="28.44" cy="29" r="2.25" />
          </g>
        </g>
      </svg>
    );
  }

}

export default Cube;