import React from 'react';
import { Link } from 'react-router-dom';
import Isologo from './isologo';

class Project extends React.Component {
  render() {
    return (
      <>
        <div className={`project | ${this.props.cols === '2' ? 'col2':''} ${this.props.project}`}>

          <div className={`image | ${this.props.project}`}></div>

          <Link to={this.props.link} className="d-flex col-12 p-0 justify-content-center | not-decoration">
            <div className="position-absolute | d-flex | align-content-end | w-100 h-100">
              <div className="category-information | w-100 | mt-auto m-3 | scale-tn2 | text-secondary | d-flex flex-wrap align-items-center gap-2">
                <div className='d-flex gap-2 mr-auto'><Isologo customClass="micro color-plata" /> {this.props.name}</div>
                <div className='d-flex flex-wrap gap-2' dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  }

}

export default Project;