import React from 'react'
import Project from '../components/project'

class Projects extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<>
				<section className="bg-overlay-site position-relative z2 top-header">
					<div className="services-gallery">
						<Project
							service="web"
							project="viajemos"
							name="Viajemos.com"
							description="
								<b class='badge badge--web'>UX UI</b>
								<b class='badge badge--branding'>Marca</b>
								<b class='badge badge--system'>Design System</b>
								<b class='badge badge--layout'>Maquetación</b>
							"
							link="viajemos"
							heigth="100"
							cols="2"
						/>
						<Project
							service="3d"
							project="mino"
							name="El Despertar"
							description="
								<b class='badge badge--concept'>Concept Art</b>
								<b class='badge badge--3d'>3D</b>
								<b class='badge badge--animation'>Animacion</b>
							"
							link="wake-up"
							heigth="100"
							cols="2"
						/>
						<Project
							service="branding"
							project="nohelia"
							name="Café de Nohelia"
							description="
								<b class='badge badge--branding'>Marca</b>
							"
							link="cafe-de-nohelia"
							heigth="none"
							cols="1"
						/>
						<Project
							service="branding"
							project="million"
							name="Million and Up"
							description="
								<b class='badge badge--web'>UX UI</b>
								<b class='badge badge--branding'>Marca</b>
							"
							link="million-and-up"
							heigth="50"
							cols="1"
						/>
						<Project
							service="web"
							project="fendi"
							name="Fendi Chateau Recidences"
							description="
								<b class='badge badge--web'>UX UI</b>
								<b class='badge badge--layout'>Maquetación</b>
							"
							link="fendi-chateau"
							heigth="50"
							cols="1"
						/>
						<Project
							service="web"
							project="protegemos"
							name="Protegemos"
							description="
								<b class='badge badge--web'>UX UI</b>
								<b class='badge badge--branding'>Marca</b>
								<b class='badge badge--system'>Design System</b>
								<b class='badge badge--layout'>Maquetación</b>
							"
							link="protegemos-seguridad"
							heigth="100"
							cols="2"
						/>
						<Project
							service="3d"
							project="robot"
							name="Creature Machine"
							description="
								<b class='badge badge--concept'>Concept Art</b>
								<b class='badge badge--3d'>3D</b>
								<b class='badge badge--animation'>Animacion</b>
							"
							link="creature-machine"
							heigth="50"
							cols="2"
						/>
						<Project
							service="web"
							project="miles"
							name="Miles Car Rental"
							description="
								<b class='badge badge--web'>UX UI</b>
								<b class='badge badge--branding'>Marca</b>
								<b class='badge badge--system'>Design System</b>
								<b class='badge badge--layout'>Maquetación</b>
							"
							link="miles-car-rental"
							heigth="none"
							cols="1"
						/>
						<Project
							service="branding"
							project="znet"
							name="ZNetWorks Group"
							description="
								<b class='badge badge--branding'>Marca</b>
							"
							link="znetworks"
							heigth="none"
							cols="2"
						/>
						<Project
							service="branding"
							project="hontus"
							name="Hountus Milano Group"
							description="
								<b class='badge badge--branding'>Marca</b>
							"
							link="projects"
							heigth="none"
							cols="1"
						/>
						<Project
							service="3d"
							project="keltoi"
							name="Zadoc"
							description="
								<b class='badge badge--concept'>Concept Art</b>
								<b class='badge badge--3d'>3D</b>
								<b class='badge badge--animation'>Animacion</b>
							"
							link="zadoc"
							heigth="50"
							cols="2"
						/>	
						<Project
							service="branding"
							project="andrea"
							name="Andrea Lopez"
							description="
								<b class='badge badge--web'>UX UI</b>
								<b class='badge badge--branding'>Marca</b>
								<b class='badge badge--layout'>Maquetación</b>
							"
							link="projects"
							heigth="50"
							cols="1"
						/>
					</div>

					<div className="col-12">
						<div className="d-flex justify-content-center box-btn mt-4 pb-3 pb-md-4">
							<a className="buttom btn-quote" rel="model">Cotiza tu Proyecto</a>
						</div>
					</div>

				</section>
			</>
		);
	}
}

export default Projects;