import React from 'react';
import { Link } from 'react-router-dom'

class OtherProjects extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      data: [
        {
          id: 'wakeUp',
          type: '3d',
          projectName: 'mino',
          title: 'El DESPERTAR',
          description: 'Diseño de Personaje, Rig, Modelado y Animacion.',
          link: 'wake-up'
        },
        {
          id: 'creatureMachine',
          type: '3d',
          projectName: 'robot',
          title: 'CREATURE MACHINE',
          description: 'Diseño de Personaje, Rig, Modelado y Animacion.',
          link: 'creature-machine'
        },
        {
          id: 'znet',
          type: 'branding',
          projectName: 'znet',
          title: 'ZNETWORKS',
          description: 'Branding, Modelado.',
          link: 'znetworks'
        },
        {
          id: 'million',
          type: 'web',
          projectName: 'million',
          title: 'MILLION AND UP',
          description: 'Diseño UI/UX - APP, Diseño de Marca, Manual de imagen.',
          link: 'million-and-up'
        },
        {
          id: 'keltoi',
          type: '3d',
          projectName: 'keltoi',
          title: 'ZADOC',
          description: 'Diseño de Personaje, Rig y Modelado.',
          link: 'zadoc'
        },
        {
          id: 'nohelia',
          type: 'branding',
          projectName: 'nohelia',
          title: 'Café DE NOHELIA',
          description: 'Branding, Diseño de Empaque, Manual de imagen.',
          link: 'cafe-de-nohelia'
        },
        {
          id: 'hontus',
          type: 'branding',
          projectName: 'hontus',
          title: 'HONTUS',
          description: 'Diseño de Logotipo.',
          link: '#'
        },
        {
          id: 'fendi',
          type: 'web',
          projectName: 'fendi',
          title: 'FENDI CHATEAU RECIDENCES',
          description: 'DISEÑO WEB - HTML Responsive',
          link: 'fendi-chateau'
        },
        {
          id: 'andrea',
          type: 'branding',
          projectName: 'andrea',
          title: 'ANDREA LOPEZ',
          description: 'DISEÑO WEB - HTML Responsive - Diseño de Marca',
          link: '#'
        },
        {
          id: 'miles',
          type: 'web',
          projectName: 'miles',
          title: 'MILES CAR RENTAL',
          description: 'Diseño UI/UX, Diseño de Marca, Manual de imagen.',
          link: 'miles-car-rental'
        },
      ]
    } 
  }

  render() {
    let numItem = 0;

    return(
      <>
        <h3 className="mt-5 mb-3">OTROS PROYECTOS</h3>

        <div className="other-projects">
          <div className="services-gallery gap-4">
            {this.state.data.map((project) => {
              if(numItem < 3 && project.type == this.props.type &&  this.props.noPrint != project.projectName) {
                {numItem++}
                return (
                  <Link to={project.link} key={project.id} className="col-11 col-md p-0">
                      <div className={`image | ${project.projectName}`}></div>
                        <div className="information-service position-relative z3 mt-auto p-3">
                          <p className="mb-0">{project.title}</p>
                          {project.description}    
                        </div>
                  </Link>
                );
              }
            })}
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-auto mt-4 pb-3">
            <Link className='d-inline-flex align-items-center' to="projects"><i className="fas fa-angle-left mr-2 scale-t1"></i> Ver todos los Proyectos</Link>
          </div>
        </div>
      </>
    );
  }
}

export default OtherProjects;
