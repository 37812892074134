
import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';

class Footer extends React.Component {

    componentDidMount() {
        this.GoSection('.btn-services','#services');
    }

    GoSection(btn, section){
        if($(section).length){
            let hHeader = 60;
            let scrollTopSection = $(section).offset().top;
            $(btn).click(function(){
                $('html, body').animate({scrollTop:(scrollTopSection - hHeader)}, 'fast');
            });
        }
    }

    render() {
        return (
            <footer>
            <div id="particle-canvas" className="particles"></div>
            <div className="container-fluid content-footer pt-4">
                <div className="row align-items-center justify-content-between pt-3 pt-3 pt-md-5 pb-md-5">
                    <div className="col-12 col-md-4 order-2 order-md-1 mb-3 mb-md-0">
                        <div className="row sirio-navigation text-center text-md-left">
                            <div className="col-12 col-md-auto">
                                {/* <a className="mb-2 d-none" href="/">DESCÚBRENOS</a> */}
                                <Link to="/#services" className="btn-services | mb-2">SERVICIOS</Link>
                                <Link to="projects" className="mb-2">PROYECTOS</Link>
                                <a className="mb-2 btn-quote">CONTACTO</a>
                            </div>
                            <div className="col-12 col-md-auto d-none">
                                <a className="mb-2" href="index.html">EXPERIENCIA 3D</a>
                                <a className="mb-2" href="index.html">ARTE CONCEPTUAL</a>
                                <a className="mb-2" href="index.html">EXPERIENCIA WEB</a>
                                <a className="mb-2" href="index.html">BRANDING & DESIGN</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-auto col-md-4 text-center order-1 order-md-2 mb-4 mb-md-0">
                        <div className="content-logo mb-3 text-center">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.9179153 69.8707809" className="logo">
                            <polygon className="sirio-ligth" points="44.4089546,21.6719246 31.0089569,16.271925 31.4089565,17.271925 41.8089561,24.3719254 
                                43.9089546,31.1719246 44.9089546,31.1719246 47.0089569,24.3719254 57.5089569,17.271925 57.9089546,16.271925 "/>
                            <path className="sirio-slogan" d="M23.6206741,68.3791656c0.006897-0.1075516-0.0250397-0.2140045-0.0899963-0.3000031
                                c-0.067627-0.0817032-0.1531754-0.1467209-0.25-0.1900024c-0.112236-0.051918-0.2295227-0.0921326-0.3499985-0.1200027
                                l-0.4200058-0.0899963c-0.1830978-0.0380936-0.3634872-0.0882034-0.5400009-0.1500015
                                c-0.1648941-0.0555496-0.3201065-0.1365204-0.4599991-0.2399979c-0.1294479-0.1029053-0.2351837-0.2325134-0.3099976-0.3799973
                                c-0.0859756-0.1844482-0.1270981-0.3866196-0.1200027-0.5899963c-0.0073166-0.2302475,0.0371017-0.459198,0.1300049-0.6699982
                                c0.1767883-0.3787766,0.5146561-0.6578903,0.9199982-0.7600021c0.2279816-0.0625534,0.4636078-0.0928497,0.6999969-0.0899963
                                c0.2649536-0.0015182,0.5294495,0.0219193,0.7900009,0.0699997c0.232933,0.0431442,0.4608002,0.1101608,0.6800003,0.1999969
                                v0.7699966c-0.2368393-0.0933609-0.4810028-0.1669464-0.7299957-0.2200012
                                c-0.2176132-0.0420456-0.4384079-0.0654602-0.6600037-0.0699997c-0.2370682-0.0102234-0.4723587,0.0451431-0.6800003,0.1600037
                                c-0.2444992,0.1855698-0.3130188,0.5239182-0.159996,0.7900009c0.0519333,0.083374,0.1241913,0.1521912,0.2099991,0.1999969
                                c0.0958405,0.0593948,0.2004623,0.1032715,0.3099976,0.1299973l0.3799973,0.0899963
                                c0.1994934,0.0381622,0.3965149,0.0882492,0.590004,0.1500015c0.1804733,0.0555267,0.3521805,0.136322,0.5100021,0.2399979
                                c0.1439514,0.1039352,0.2606049,0.2411804,0.3399963,0.4000015c0.0950241,0.1959305,0.1397018,0.4124603,0.1299973,0.6299973
                                c0.0028992,0.2373352-0.0448227,0.4725647-0.1399994,0.6900024c-0.0982742,0.1894684-0.2427292,0.35112-0.4199982,0.4700012
                                c-0.1963806,0.1347427-0.4171753,0.2298508-0.6500015,0.2799988c-0.2756882,0.0617142-0.5574951,0.0919037-0.8399963,0.0899963
                                c-0.2649689,0.0019379-0.5295105-0.0214996-0.7900009-0.0699997c-0.2218857-0.0455704-0.4369888-0.1195145-0.6399994-0.2200012
                                v-0.7600021c0.2264786,0.0961456,0.4606323,0.1730881,0.6999969,0.2300034
                                c0.2342377,0.0435715,0.4717712,0.0669937,0.7099991,0.0699997c0.1395874,0.0152817,0.2804184,0.0152817,0.4200058,0
                                c0.1296234-0.0170593,0.2550354-0.0577316,0.3699951-0.1200027c0.1052246-0.0480728,0.1951752-0.1241837,0.2600021-0.2200012
                                C23.5994263,68.6614685,23.6347809,68.5200577,23.6206741,68.3791656z"/>
                            <path className="sirio-slogan" d="M30.5406799,69.7791672h-0.9000015v-4.1800003h-1.5800018v-0.75h4.0599976v0.75h-1.5799942V69.7791672z"/>
                            <path className="sirio-slogan" d="M37.8406754,69.8691635c-0.2352676,0.0042038-0.4702148-0.0192947-0.6999969-0.0699997
                                c-0.1892929-0.0382309-0.3714752-0.1057053-0.5400009-0.1999969c-0.1529083-0.0880508-0.2884674-0.20327-0.4000015-0.3399963
                                c-0.1077805-0.1404037-0.1953049-0.2952499-0.2600021-0.4599991c-0.0515976-0.1466599-0.0917206-0.2971115-0.1199951-0.4499969
                                c-0.0110626-0.1865005-0.0110626-0.373497,0-0.5599976v-2.9000092h0.9000015v2.8000031
                                c-0.0120163,0.275177,0.028717,0.5501175,0.1199951,0.8099976c0.0738831,0.2019577,0.2199478,0.3693924,0.4100037,0.4700012
                                c0.3962631,0.1996765,0.8637314,0.1996765,1.2599945,0c0.1845627-0.1046219,0.3262329-0.2710876,0.4000015-0.4700012
                                c0.0988388-0.2581177,0.1431046-0.5339127,0.1300049-0.8099976v-2.8000031h0.8899994v2.8799973
                                c0.011055,0.1865005,0.011055,0.373497,0,0.5599976c-0.0186539,0.154892-0.0590515,0.3063812-0.1200027,0.4499969
                                c-0.0607376,0.1666031-0.1485901,0.3220291-0.2599945,0.4599991c-0.1129227,0.1392517-0.2522583,0.2547989-0.4100037,0.3399963
                                c-0.1700058,0.1030426-0.3558273,0.1773682-0.5499954,0.2200012C38.3446121,69.8539124,38.0926285,69.8774338,37.8406754,69.8691635
                                z"/>
                            <path className="sirio-slogan" d="M44.0506744,69.7791672v-4.8899994h1.6500015h0.340004h0.3300018
                                c0.0985336,0.0193481,0.1954803,0.0460892,0.2900009,0.0800018c0.2480316,0.072319,0.4815369,0.1873779,0.6899948,0.3399963
                                c0.1986237,0.1347198,0.371376,0.3040848,0.5100021,0.5c0.131691,0.196991,0.2328339,0.4127579,0.2999954,0.6399994
                                c0.0736389,0.2534332,0.1106796,0.5160904,0.1100006,0.7799988c0.0015182,0.2494888-0.0287247,0.4981461-0.0899963,0.7399979
                                c-0.0568466,0.2227097-0.147934,0.4352417-0.2700043,0.6299973c-0.1206894,0.1908798-0.2728424,0.3599319-0.4499969,0.5
                                c-0.1940155,0.1569824-0.4140015,0.2788239-0.6500015,0.3600006c-0.2249069,0.0870056-0.4602432,0.1441574-0.7000046,0.1699982
                                c-0.2947617,0.0420532-0.5922546,0.0621109-0.8899994,0.0599976L44.0506744,69.7791672z M45.3206787,69.0291672
                                c0.4201813,0.016983,0.8392487-0.0545654,1.2299957-0.2099991c0.2530365-0.1213379,0.4600143-0.3212967,0.590004-0.5699997
                                c0.1474152-0.2905579,0.216362-0.6145935,0.1999969-0.9400024c0.0020828-0.1817703-0.0180664-0.363121-0.0599976-0.5400009
                                c-0.0429916-0.1514816-0.1069412-0.2962189-0.1900024-0.4300003c-0.0811539-0.1257629-0.182518-0.2372589-0.2999954-0.3300018
                                c-0.1242142-0.0888214-0.2584763-0.1626663-0.4000015-0.2200012c-0.1530838-0.0529251-0.3102875-0.0930634-0.4700012-0.1200027
                                c-0.1898422-0.0110397-0.3801651-0.0110397-0.5699997,0H45.000679v3.3899994L45.3206787,69.0291672z"/>
                            <path className="sirio-slogan" d="M52.2206802,69.7791672v-4.8899994h0.8899994v4.8899994H52.2206802z"/>
                            <path className="sirio-slogan" d="M61.8406754,67.3391647c-0.001564,0.3395767-0.0555267,0.6768875-0.159996,1
                                c-0.101944,0.2952499-0.2617264,0.5672226-0.4700012,0.8000031c-0.2055054,0.2324524-0.4622726,0.4139023-0.75,0.5299988
                                c-0.3187408,0.1234589-0.6582184,0.1845627-1,0.1800003c-0.3418427,0.0053177-0.6814651-0.0558167-1-0.1800003
                                c-0.2877274-0.1160965-0.5444946-0.2975464-0.75-0.5299988c-0.2117615-0.2302246-0.371994-0.5029526-0.4700012-0.8000031
                                c-0.2135468-0.6666489-0.2135468-1.3833542,0-2.0500031c0.0980072-0.2970505,0.2582397-0.5697784,0.4700012-0.8000031
                                c0.2055054-0.2324524,0.4622726-0.4139023,0.75-0.5299988c0.3185349-0.1241837,0.6581573-0.185318,1-0.1800003
                                c0.3417816-0.0045624,0.6812592,0.0565414,1,0.1800003c0.2877274,0.1160965,0.5444946,0.2975464,0.75,0.5299988
                                c0.2082748,0.2327805,0.3680573,0.5047531,0.4700012,0.8000031C61.7900162,66.6283112,61.8440399,66.9828415,61.8406754,67.3391647z
                                M60.910675,67.3391647c-0.0020142-0.2505875-0.0390396-0.4996567-0.1100006-0.7399979
                                c-0.0587234-0.2030029-0.1611099-0.3907242-0.2999954-0.5500031c-0.1279068-0.1442184-0.2846069-0.2600327-0.4599991-0.3399963
                                c-0.379715-0.1464081-0.800293-0.1464081-1.1800003,0c-0.1753998,0.0799637-0.3320999,0.1957779-0.4599991,0.3399963
                                c-0.1388855,0.1592789-0.2412796,0.3470001-0.3000031,0.5500031c-0.1464539,0.5130844-0.1464539,1.0569153,0,1.5699997
                                c0.0587234,0.2030029,0.1611176,0.3907242,0.3000031,0.5500031c0.1278992,0.1442184,0.2845993,0.2600327,0.4599991,0.3399963
                                c0.3797073,0.1464081,0.8002853,0.1464081,1.1800003,0c0.1753922-0.0799637,0.3320923-0.1957779,0.4599991-0.3399963
                                c0.1388855-0.1592789,0.241272-0.3470001,0.2999954-0.5500031C60.8807602,67.9000092,60.9178848,67.6198959,60.910675,67.3391647z"
                                />
                            <path className="sirio-slogan" d="M68.1906815,68.3791656c0.006897-0.1075516-0.0250397-0.2140045-0.0899963-0.3000031
                                c-0.0656509-0.0837402-0.151741-0.1491699-0.25-0.1900024c-0.1116333-0.0533981-0.2290878-0.0936737-0.3499985-0.1200027
                                l-0.4100037-0.0899963c-0.1860657-0.0393677-0.3697128-0.0894547-0.5500031-0.1500015
                                c-0.1617966-0.0555801-0.3137054-0.1365967-0.4499969-0.2399979c-0.1350021-0.0998077-0.2446213-0.2299805-0.3199997-0.3799973
                                c-0.0859756-0.1844482-0.1270981-0.3866196-0.1200027-0.5899963c-0.0041809-0.2310104,0.043663-0.4599915,0.1399994-0.6699982
                                c0.0827942-0.1875153,0.2097397-0.3522034,0.3700027-0.4800034c0.1571198-0.1320572,0.3415375-0.2276764,0.5400009-0.2799988
                                c0.2313843-0.0625839,0.470314-0.0928726,0.7099991-0.0899963c0.2616348-0.0015488,0.5228195,0.0218964,0.7799988,0.0699997
                                c0.233284,0.0416718,0.4613037,0.1087418,0.6800003,0.1999969v0.7699966
                                c-0.2363586-0.0947571-0.4806442-0.1683731-0.7300034-0.2200012c-0.214325-0.041481-0.4317551-0.0649033-0.6500015-0.0699997
                                c-0.2403793-0.0115585-0.479248,0.0438309-0.6900024,0.1600037c-0.154274,0.1141891-0.2407227,0.2983551-0.2300034,0.4899979
                                c-0.0051727,0.1045761,0.0190811,0.2085114,0.0699997,0.3000031c0.0581665,0.0816498,0.133194,0.1498566,0.2200012,0.1999969
                                c0.0952606,0.0605164,0.2000732,0.1044693,0.3099976,0.1299973l0.3700027,0.0899963
                                c0.2027664,0.0381927,0.4031143,0.0882797,0.5999985,0.1500015c0.1773529,0.0555115,0.3457565,0.1363449,0.5,0.2399979
                                c0.1468124,0.1036072,0.2667923,0.2407379,0.3499985,0.4000015c0.0902863,0.1975098,0.1347275,0.4128799,0.1299973,0.6299973
                                c0.0048599,0.2385864-0.0465317,0.474968-0.1500015,0.6900024c-0.0982819,0.1894684-0.2427292,0.35112-0.4199982,0.4700012
                                c-0.1953354,0.1366272-0.4165268,0.2319107-0.6500015,0.2799988c-0.2756882,0.0617142-0.5574951,0.0919037-0.8399963,0.0899963
                                c-0.2649612,0.0019379-0.5295029-0.0214996-0.7900009-0.0699997c-0.2214432-0.0471878-0.436348-0.1210632-0.6399994-0.2200012
                                v-0.7600021c0.229332,0.0976868,0.4669495,0.1746597,0.7099991,0.2300034
                                c0.2341614,0.0440674,0.4717407,0.0674973,0.7099991,0.0699997c0.1396103,0.01474,0.2803879,0.01474,0.4199982,0
                                c0.126091-0.0186462,0.2479401-0.0592575,0.3600006-0.1200027c0.1052246-0.0480728,0.1951752-0.1241837,0.2600021-0.2200012
                                C68.1694183,68.6614761,68.2047806,68.5200577,68.1906815,68.3791656z"/>
                            <path className="sirio-base" d="M8.1500015,56.6839066c0.0258331-0.3572655-0.077034-0.7119865-0.2900009-1
                                c-0.2087669-0.2631683-0.4804573-0.4695168-0.7900009-0.5999985c-0.3607521-0.1698189-0.7399368-0.2973328-1.1299973-0.3800011
                                l-1.3100014-0.2900009c-0.5843315-0.1269569-1.1617584-0.2838287-1.7299995-0.4700012
                                c-0.5247688-0.1534424-1.0188103-0.3970795-1.4599991-0.7199974c-0.4215813-0.3255272-0.7635498-0.7427292-1-1.2200012
                                c-0.2667618-0.5861778-0.3934364-1.2264023-0.3700027-1.8699951C0.0524979,49.3942566,0.1993637,48.659935,0.5,47.9839096
                                c0.274437-0.5831108,0.673893-1.0986481,1.170002-1.5099983c0.5149803-0.4117966,1.1063271-0.7176666,1.7399979-0.9000015
                                c0.7294846-0.2006798,1.4834633-0.2982941,2.2400017-0.2900009c0.839035,0.0010223,1.6760941,0.0813828,2.5,0.2400017
                                c0.7425957,0.1368408,1.4695778,0.3479004,2.1699982,0.6300011v2.4500008C9.5686226,48.303196,8.7923126,48.0689621,8,47.9039116
                                c-0.6885567-0.1352615-1.3882942-0.2055664-2.0900002-0.2099991c-0.7629471-0.0412636-1.5211525,0.1430702-2.1799965,0.5299988
                                C3.2379684,48.568367,2.9612541,49.1445389,3,49.7439117c-0.0183296,0.3508148,0.068737,0.6990852,0.25,0.9999962
                                c0.1761284,0.2587051,0.4086227,0.4741058,0.6800003,0.6300011c0.3137779,0.1778069,0.6501579,0.3123589,1,0.4000015
                                c0.3700027,0.0999985,0.7700005,0.2000008,1.1900024,0.2799988c0.6666641,0.1399994,1.2966652,0.2999992,1.8899994,0.4799995
                                c0.5705223,0.1708908,1.1105003,0.4307518,1.5999985,0.7700005c0.4705124,0.3237038,0.8521881,0.7603951,1.1100006,1.2700005
                                c0.2877846,0.6262512,0.4247818,1.3112373,0.4000015,2c0.0197678,0.7526894-0.1378098,1.4994698-0.4600029,2.1800003
                                c-0.3251953,0.5946388-0.7923965,1.0996284-1.3599968,1.4699974C8.66745,60.6462402,7.962265,60.947979,7.2200012,61.1139069
                                c-0.8793793,0.1983757-1.7785378,0.2956734-2.6800003,0.2900009C3.6893806,61.4322357,2.8381271,61.3719101,2,61.2239075
                                c-0.6991234-0.1327286-1.3736763-0.3721962-2-0.7099991v-2.4400024c1.41502,0.6505508,2.9526253,0.9914856,4.5100021,1
                                c0.4422455-0.0010109,0.8836861-0.0377998,1.3199997-0.1100006C6.2395058,58.8857346,6.6342735,58.7440224,7,58.5439072
                                c0.3376884-0.1676979,0.6268578-0.4189949,0.8400002-0.7299995C8.0579414,57.4784698,8.1662712,57.0835991,8.1500015,56.6839066z"/>
                            <path className="sirio-base" d="M26.0999985,61.1539078v-15.579998H29v15.579998H26.0999985z"/>
                            <path className="sirio-base" d="M62,61.1539078v-15.579998h2.8499985v15.579998H62z"/>
                            <path className="sirio-base" d="M88.9166718,53.3639069c0.0107422,1.1065559-0.1581116,2.2075272-0.5,3.2599983
                                c-0.3061218,0.9606133-0.8103027,1.846344-1.4799957,2.6000023c-0.6647949,0.7224884-1.4808807,1.2893105-2.3899994,1.6599998
                                c-2.0721283,0.8000336-4.3678741,0.8000336-6.4400024,0c-0.9002762-0.3710327-1.7065048-0.9381256-2.3600006-1.6599998
                                c-0.6805878-0.7491264-1.1921463-1.6358299-1.5-2.5999985c-0.6797485-2.1235962-0.6797485-4.4064026,0-6.529995
                                c0.3126144-0.9471588,0.8240356-1.8165741,1.5-2.5500031c0.6586609-0.7274208,1.4719009-1.2980576,2.3799973-1.6699982
                                c2.0745697-0.7868309,4.3654251-0.7868309,6.4399948,0c0.9097595,0.3741837,1.7257996,0.9443893,2.3899994,1.6699982
                                c0.6649017,0.738121,1.1689148,1.6065292,1.480011,2.5499992C88.7718201,51.1509247,88.9338989,52.2551651,88.9166718,53.3639069z
                                M85.9766693,53.3639069c0.0138245-0.8431702-0.1042786-1.6833153-0.3500061-2.4900017
                                c-0.2086029-0.6522522-0.5489655-1.2547035-1-1.7699966c-0.3942413-0.4657097-0.8950958-0.8293457-1.4599915-1.0600014
                                c-1.1994781-0.4670486-2.5305328-0.4670448-3.7300034,0c-0.5692978,0.2278328-1.0740356,0.5917969-1.4700012,1.0600014
                                c-0.4510269,0.5152931-0.7913971,1.1177444-1,1.7699966c-0.4722519,1.633213-0.4722519,3.366787,0,5
                                c0.2086029,0.652256,0.5489731,1.2547073,1,1.7700005c0.398674,0.465271,0.9026337,0.8286743,1.4700012,1.0600014
                                c1.1967926,0.4811249,2.5332108,0.4811249,3.7300034,0c0.5628204-0.2343597,1.0628662-0.5974083,1.4599915-1.0600014
                                c0.4510345-0.5152931,0.7913971-1.1177444,1-1.7700005C85.8759613,55.0611496,85.9941101,54.2138557,85.9766693,53.3639069z"/>
                            <path className="sirio-base" d="M47.6699982,54.7439079L47.6699982,54.7439079c1.0796356-0.2610855,2.051239-0.851738,2.7799988-1.6899986
                                c0.6357346-0.8021698,0.959053-1.8076401,0.9100037-2.829998c0.0348663-0.921505-0.2281265-1.8297157-0.75-2.5900002
                                c-0.533638-0.7214317-1.2724838-1.2651138-2.1199951-1.5600014c-0.5614777-0.1921234-1.1411743-0.3261566-1.7300034-0.4000015
                                c-0.7263718-0.0911446-1.4579391-0.1345711-2.1899948-0.1300011h-4.7400055l2.8499985,2.3899994h2
                                c0.5112305-0.0085411,1.0224457,0.0181923,1.5299988,0.0800018c0.3452301,0.0439529,0.6821442,0.1382904,1,0.2799988
                                c0.3755188,0.142971,0.6899872,0.4115028,0.8899994,0.7599983c0.2132111,0.3527336,0.3240128,0.7578583,0.3199997,1.1700058
                                c0.008461,0.4550705-0.1055069,0.9040565-0.3300018,1.2999954c-0.2826462,0.4245567-0.7028732,0.738842-1.1899948,0.8899994
                                C46.527832,52.5441399,46.1419907,52.6313744,45.75,52.6739044c-0.5508347,0.0655785-1.1053085,0.0956383-1.6600037,0.0900002
                                h-1.409996v-1.8499985l-2.8499985,2.5499992v7.6899986h2.8499985v-6h2l4.2399979,6h3.3800049L47.6699982,54.7439079z"/>
                            <polygon className="sirio-ligth" points="38.6200027,47.3939095 41.0500031,49.4339104 38.6100006,51.5039062 "/>
                            <g>
                                <path className="sirio-base" d="M56.550724,8.7014771c2.2365723,2.7092896,3.5801392,6.1829834,3.5801392,9.970459
                                    c0,8.6553345-7.0165405,15.671875-15.671936,15.671875c-8.6553345,0-15.671875-7.0165405-15.671875-15.671875
                                    c0-3.7875366,1.343689-7.2612305,3.5802612-9.97052L30.252018,6.5861816
                                    c-2.7778931,3.260376-4.4649658,7.4768066-4.4649658,12.0857544c0,10.2957153,8.3761597,18.671875,18.671875,18.671875
                                    c10.2957764,0,18.671936-8.3761597,18.671936-18.671875c0-4.6088867-1.6870728-8.8252563-4.4649048-12.0856323L56.550724,8.7014771
                                    z"/>
                                <path className="sirio-ligth" d="M34.4884682,6.5803223C37.1977577,4.34375,40.6713905,3,44.4589272,3
                                    c3.7875977,0,7.2613525,1.34375,9.9706421,3.5803833l2.1152954-2.1152344C53.2844276,1.6871948,49.0679359,0,44.4589272,0
                                    c-4.6089478,0-8.8253784,1.6871948-12.0857544,4.4650879L34.4884682,6.5803223z"/>
                            </g>
                            </svg>
                        </div>
                        <div className="copyright">
                            Copyright © 2018-2023 Sirio Studios. Todos los derechos reservados.
                        </div>
                    </div>
                    <div className="col-12 col-md-4 order-3 order-md-3">
                        <div className="row">
                            <div className="col-12 content-phone text-center text-md-right mb-4 | btn-quote | pointer">
                                <div className="region">Bogotá, Colombia</div>
                                <div className="phone">(+57) 313.257.2108</div>
                                <div className="get-in-touch">¡Ponte en contacto!</div>
                            </div>
                            <div className="col-12 d-flex justify-content-center justify-content-md-end mb-2">
                                <a href="mailto:siriostudiosdesign@gmail.com">siriostudiosdesign@gmail.com</a>
                            </div>
                            <div className="social-media col-12 text-right d-flex justify-content-center justify-content-md-end gap-5 scale-t2">
                                <a target="_blank" href="https://www.behance.net/siriostudiosdesign/">
                                    <i className="fab fa-behance"></i>
                                </a>
                                <a target="_blank" href="https://www.artstation.com/siriostudiosdesign/">
                                    <i className="fab fa-artstation"></i>
                                </a>
                                <a target="_blank" href="https://www.facebook.com/siriostudiosdesign/">
                                    <i className="fab fa-facebook"></i>
                                </a>
                                <a target="_blank" href="https://www.instagram.com/siriostudiosdesign/">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-page pt-4 pb-4 text-center mt-4 scale-tn1">
                    <a target={"_blank"} className='d-block mb-3 not-decoration' rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
                        <img alt="Licencia Creative Commons" src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png" />
                    </a>
                    Esta obra está bajo una <a target={"_blank"} rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">Licencia Creative Commons Atribución-NoComercial-Sin Derivadas 4.0 Internacional</a>.
                </div>
            </div>
            </footer>
        );
    }
}

export default Footer;

