import React from 'react'
import SliderInside from '../components/slider_inside'
import OtherProjects from '../components/other_projects'
import BoxProject from '../components/box_project'

// Assets
import fendiImage1 from '../assets/images/projects/fendi/diseno-web-001.webp'
import fendiImage2 from '../assets/images/projects/fendi/diseno-web-002.webp'
import fendiImage3 from '../assets/images/projects/fendi/diseno-web-003.webp'
import fendiImage4 from '../assets/images/projects/fendi/diseno-web-004.webp'
import fendiImage5 from '../assets/images/projects/fendi/diseno-web-005.webp'
import fendiImage6 from '../assets/images/projects/fendi/diseno-web-006.webp'



class FendiChateau extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<>
				<SliderInside
					service="web"
					project="fendichateau"
					name="Fendi Chateau Recidences"
					description="
					<b class='badge badge--web'>UX UI</b>
					<b class='badge badge--layout'>Maquetación</b>
					"
				/>

				<section className="container-fluid bg-overlay-site position-relative z2">
					<div className="row justify-content-center services-gallery">
						<BoxProject
							elementType="image"
							elementSrc={fendiImage1}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={fendiImage2}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={fendiImage3}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={fendiImage4}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={fendiImage5}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={fendiImage6}
							layout="col-12 none-info"
						/>
					</div>

					<div className="col-12">
						<div className="d-flex justify-content-center box-btn mt-4 pb-3 pb-md-4">
							<a className="buttom btn-quote" rel="model">Cotiza tu Proyecto</a>
						</div>
					</div>

					<OtherProjects
						type='web'
						noPrint='fendi'
					/>

				</section>
			</>
		);
	}
}

export default FendiChateau;