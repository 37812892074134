import React from 'react';
import { Link } from 'react-router-dom';


class BoxClients extends React.Component {
  render() {
    return(
      <>
        <div className="col-12 col-sm-6 col-md-3 mb-4 c1">
          <Link to={this.props.link} className="box-client | not-decoration | d-block">
            <div className="box-client-front d-flex align-items-center justify-content-center">
              <img className={`client ${this.props.customClass}`} src={this.props.image} alt="Miles Car Rental" />
            </div>
            <div className="box-client-back  d-flex align-items-center justify-content-center">
              <div className="inner text-center | pt-3 pr-5 pl-5">
                <p>{this.props.description}</p>
                {this.props.link &&
                  <span className="d-block buttom mt-4">VER PROYECTO</span>
                }
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default BoxClients;
