
import React from 'react';

class Branding extends React.Component {
  render() {
    return (
      <svg className="branding" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.29 74.51">
        <g data-name="Capa 2">
          <g data-name="Capa 1">
            <circle className="cls-2 p1" cx="6.5" cy="73.51" r="1" />
            <circle className="cls-2 p2" cx="7.16" cy="68.89" r="1.38" />
            <circle className="cls-2 p3" cx="10.89" cy="62.14" r="1.5" />
            <circle className="cls-2 p4" cx="16.9" cy="56.32" r="2" />
            <circle className="cls-2 p5" cx="24.73" cy="47.69" r="2.5" />
            <circle className="cls-2 p6" cx="27.29" cy="39.4" r="3" />
            <circle className="cls-2 p7" cx="24.73" cy="31.11" r="2.5" />
            <circle className="cls-2 p8" cx="19.4" cy="24.67" r="2" />
            <circle className="cls-2 p9" cx="9.16" cy="13.44" r="1.38" />
            <circle className="cls-2 p10" cx="8.5" cy="8.2" r="1" />
            <circle className="cls-2 p11" cx="21.92" cy="67.26" r="1" />
            <circle className="cls-2 p12" cx="21.26" cy="62.64" r="1.38" />
            <circle className="cls-2 p13" cx="10.54" cy="50.65" r="2" />
            <circle className="cls-2 p14" cx="5.56" cy="44.02" r="2.5" />
            <circle className="cls-2 p15" cx="3" cy="35.73" r="3" />
            <circle className="cls-2 p16" cx="5.56" cy="27.44" r="2.5" />
            <circle className="cls-2 p17" cx="19.4" cy="13.32" r="1.5" />
            <circle className="cls-2 p18" cx="13.39" cy="19" r="2" />
            <circle className="cls-2 p19" cx="23.13" cy="6.24" r="1.38" />
            <circle className="cls-2 p20" cx="23.79" cy="1" r="1" />
            <line className="cls-1 s1" x1="3" y1="35.73" x2="19.9" y2="38.88" />
            <line className="cls-1 s2" x1="5.56" y1="27.44" x2="16.9" y2="30.24" />
            <line className="cls-1 s3" x1="5.56" y1="44.02" x2="17.45" y2="46.32" />
          </g>
        </g>
      </svg>
    );
  }

}

export default Branding;