import React from 'react'

// Components
import Header from './components/header'
import Footer from './components/footer'
import ModalQuote from './components/modal_quote'
import imgMobile from './assets/images/SVG/mobile.svg'

function Layout(props) {
  return (
    <>
      <Header/>
      <main className='main'>
        {props.children}
      </main>
      <Footer/>
      <ModalQuote/>
      <div className="lock-landscape">
        <div className="lock-landscape__logo">
          <svg className="logo iso-logo large d-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172.7 166.63">
            <g data-name="Capa 2">
              <g data-name="Capa 1">
                <polygon className="sirio-ligth" points="83.19 97.13 20.32 72.34 22.24 76.76 70.81 110.02 80.96 141.85 85.42 141.85 95.56 110.02 144.61 76.76 146.31 72.34 83.19 97.13" /><path className="sirio-ligth" d="M33,29.51a73.61,73.61,0,0,1,100.46-.08l6.83-6.86a83.29,83.29,0,0,0-114.13.1Z" /><path className="sirio-base" d="M143.88,26.1,137,33A73.64,73.64,0,1,1,29.51,33L22.67,26.2a83.31,83.31,0,1,0,121.21-.1Z" /><path className="sirio-ligth" d="M168,32.44,166.5,30l-.82,0v2.48h-.78v-5.7l.6,0,.82,0c1.37,0,2.06.54,2.06,1.63a1.56,1.56,0,0,1-.33,1,1.42,1.42,0,0,1-.79.53l1.66,2.62Zm-2.34-5V29.3a5.09,5.09,0,0,0,.54,0,1.79,1.79,0,0,0,1-.23.9.9,0,0,0,.32-.8.74.74,0,0,0-.34-.69,2.14,2.14,0,0,0-1.08-.21Z" /><path className="sirio-ligth" d="M166.6,35.67a6.11,6.11,0,1,1,6.1-6.11A6.12,6.12,0,0,1,166.6,35.67Zm0-10.74a4.64,4.64,0,1,0,4.63,4.63A4.64,4.64,0,0,0,166.6,24.93Z" />
              </g>
            </g>
          </svg>
        </div>
        <div className="lock-landscape__icon">
          <img className="lazyload lock-landscape__icon__img" src={imgMobile} alt="Mobile" />
        </div>
        <div className="lock-landscape__info text-primary scale-t1">
          Por favor gira tu Dispositivo
        </div>
      </div>
    </>
  )
}

export default Layout;