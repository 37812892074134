import React from 'react';

class Isologo extends React.Component {
  render() {
    return(
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.6300049 166.6300049" className={`logo iso-logo ${this.props.customClass}`}>
            <g>
                <polygon className="sirio-ligth"  points="83.0918655,96.7012405 23.3003826,72.606163 25.0852051,77.0682144 71.4905396,108.7487869 
                    80.8608398,139.0907288 85.3228912,139.0907288 94.6931992,108.7487869 141.5447388,77.0682144 143.3295593,72.606163 	"/>
                <g>
                    <path className="sirio-base" d="M137.2690887,38.8264389c9.9796906,12.0889893,15.974762,27.5887909,15.974762,44.4887009
                        c0,38.6205444-31.3081665,69.9287109-69.9289856,69.9287109c-38.6205482,0-69.9287109-31.3081665-69.9287109-69.9287109
                        c0-16.9001846,5.9956093-32.3999825,15.9753094-44.4889717l-9.4385567-9.4382858C7.5278053,43.9358482,0,62.7497787,0,83.3151398
                        c0,45.9400024,37.374855,83.3148651,83.3148651,83.3148651c45.9402771,0,83.3151398-37.3748627,83.3151398-83.3148651
                        c0-20.5650902-7.5278168-39.378746-19.9226379-53.926712L137.2690887,38.8264389z"/>
                    <path className="sirio-ligth" d="M38.8261642,29.3617382c12.0889893-9.9797001,27.5885162-15.9755821,44.4887009-15.9755821
                        c16.9004517,0,32.400528,5.995882,44.4895172,15.9758549l9.4385529-9.4382858
                        C122.6947021,7.5283523,103.8805008,0.0000024,83.3148651,0.0000024c-20.565361,0-39.3792915,7.5283499-53.9272575,19.9234524
                        L38.8261642,29.3617382z"/>
                </g>
            </g>
        </svg>
    );
  }
}

export default Isologo;
