import React from 'react';
import $ from 'jquery';

class Quote extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.layoutInput();
		this.checkboxButtom();
	}

	/*-------------------------------------------------------------------------------*/
	// Layout Input Text, Email, Tel, Search, Password
	/*-------------------------------------------------------------------------------*/

	layoutInput() {
		$('input[type="text"], input[type="email"], input[type="tel"], input[type="search"], input[type="password"]').each(function () {
			let isoInput = $(this).attr('rel');
			if(!$(this).parent().hasClass('layout-input')){
				if (isoInput) {
					let HTML = "<div class='layout-input d-flex align-items-center'></div>";
					$(this)
						.after(HTML)
						.prev()
						.addClass("label-input-anim")
						.prependTo($(this).next())
						.parent()
						.prev()
						.appendTo($(this).next());
				} else {
					let HTML = "<div class='layout-input d-flex align-items-center'></div>";
					$(this)
						.after(HTML)
						.prev()
						.addClass("label-input-anim")
						.prependTo($(this).next())
						.parent()
						.prev()
						.appendTo($(this).next());
				}
			}
		});

		$('input[type="text"], input[type="email"], input[type="tel"], input[type="search"], input[type="password"]').focusin(function () {
			$(this).parent().addClass('enabled-input');
		});

		$('input[type="text"], input[type="email"], input[type="tel"], input[type="search"], input[type="password"]').blur(function () {
			if ($(this).val() === '') {
				$(this).parent().removeClass('enabled-input');
			}
		});
	}


	/*-------------------------------------------------------------------------------*/
	// Layout Check Box y Radio Buttom
	/*-------------------------------------------------------------------------------*/

	checkboxButtom() {
		let idCheck = 1;

		$('input:checkbox').each(function () {
			if (!$(this).parent().hasClass('layout-radio')) {
				$(this).after('<span class="checkboxButtom' + idCheck + ' layout-radio d-flex align-items-center justify-content-center mr-2"><i class="fa fa-check"></i></span>');
				$(this).appendTo('.checkboxButtom' + idCheck);
				idCheck++;
			}
		});

		$('input:checkbox').change(function () {
			if ($(this).is(':checked')) {
				$(this).parent().addClass('checkbox-selected-quicksearch');
			} else {
				$(this).parent().removeClass('checkbox-selected-quicksearch');
			}
		});
		setTimeout(this.layoutCheckboxButtom, 10);
	}

	layoutCheckboxButtom() {
		$('input:checkbox').each(function () {
			if ($(this).is(':checked')) {
				$(this).parent().addClass('checkbox-selected-quicksearch');
			} else {
				$(this).parent().removeClass('checkbox-selected-quicksearch');
			}
		});
	}

	render() {
		return (
			<>
				<div className="form">
					<form>
						<div className="row">
							<div className="col-12 col-md-4">
								<label>Email</label>
								<input id="email" type="email" />
							</div>
							<div className="col-12 col-md-4">
								<label>Name</label>
								<input id="name" type="text" />
							</div>
							<div className="col-12 col-md-4">
								<label>Phone</label>
								<input id="phone" type="tel" />
							</div>
						</div>
						<div className="row mt-3">
							<div className="col mb-3 mb-md-0">
								<div className="row h-100 align-items-center">
									<div className="col-6 col-md-auto d-flex align-items-center mb-2 mb-md-0">
										<input id={`web${this.props.suffix}`} type="checkbox" />
										<label>WEB</label>
									</div>
									<div className="col-6 col-md-auto d-flex align-items-center mb-2 mb-md-0">
										<input id={`animation-3d${this.props.suffix}`} type="checkbox" />
										<label>3D</label>
									</div>
									<div className="col-6 col-md-auto d-flex align-items-center mb-2 mb-md-0">
										<input id={`branding${this.props.suffix}`} type="checkbox" />
										<label>Branding and Design</label>
									</div>
									<div className="col-6 col-md-auto d-flex align-items-center mb-2 mb-md-0">
										<input id={`concept-art${this.props.suffix}`} type="checkbox" />
										<label>Arte Conceptual</label>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<a className="buttom">Cotiza tu Proyecto</a>
							</div>
						</div>
					</form>
				</div>
			</>
		);
	}
}

export default Quote;
