
import React from 'react';

class Art extends React.Component {
  render() {
    return (
      <svg className="concept-art" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.53 47.77">
        <g data-name="Capa 2">
          <g data-name="Capa 1">
            <path className="cls-1 s1" d="M76.4,23h9.24a14.88,14.88,0,0,1,8.26,2.5l23.66,15.77a3.3,3.3,0,0,1-1.84,6.05H38.55a12.14,12.14,0,0,1-6.71-2L.91,24.61A.9.9,0,0,1,1.41,23H61.76" />
            <polygon className="cls-1 s2" points="12.14 26.11 26.12 26.11 53.38 42.54 39.82 42.54 12.14 26.11" />
            <line className="cls-1 s3" x1="30.82" y1="37.2" x2="44.7" y2="37.2" />
            <line className="cls-1 s3" x1="17.64" y1="29.35" x2="31.52" y2="29.35" />
            <polygon className="cls-1 s3" points="37.76 33.02 23.97 33.02 22.1 32.02 35.93 32.02 37.76 33.02" />
            <path className="cls-1 s3" d="M73.27,26.11H83.33a5.94,5.94,0,0,1,3.08.86l23.24,14.3a.69.69,0,0,1-.39,1.27H53.38" />
            <line className="cls-1 s3" x1="26.12" y1="26.11" x2="58.19" y2="26.11" />
            <polygon className="cls-1 s1" points="59.35 33.34 62.38 32.34 63.47 31.04 91.03 4.52 88.03 1.69 60.47 28.21 59.13 29.26 58.1 32.17 59.35 33.34" />
            <polygon className="cls-1 s2" points="57.81 34.36 57.14 33.73 58.31 32.36 59.24 33.24 57.81 34.36" />
            <line className="cls-1 s3" x1="72.31" y1="16.82" x2="75.31" y2="19.65" />
            <line className="cls-1 s3" x1="81.46" y1="8.01" x2="84.46" y2="10.84" />
            <polygon className="cls-1 s3" points="92.06 3.21 89.39 0.69 88.19 1.84 90.86 4.36 92.06 3.21" />
          </g>
        </g>
      </svg>
    );
  }

}

export default Art;