
import React from 'react';

class Wire extends React.Component {
  render() {
    return (
      <svg className="wire-face" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.07 509.89">
        <g data-name="Capa 2">
          <g data-name="Capa 1">
            <path d="M185.64,499.57s-5.09-.19-11.56-5.48-18.4-20.76-18.4-20.76a95.54,95.54,0,0,1-13.32-28.79c-4.11-16.05-10.77-22.12-10.77-22.12l-6.86-4.12-11.35-3.72-4.51-9a121.86,121.86,0,0,1-2.94-15.47c-1-8.22,3.14-16.45,3.14-16.45l-3.14-.78L94.19,361.32l-.59-4.7-.39-9.21-3.53-20-4.11-20.56-5.29-26.44-6.46-28.2-1.76-30-1.77-26.44L62.07,174.1l-10-26.43c-6.46-20.56-11.16-45.83-11.16-45.83l-3.8-90.71" />
            <path d="M69.38,149.64l7.34,23,7.43,21,4.33,27.23,10.8,58.75,11.47,45.21,1.86,16.7,1.24,6.81,2.27,7.26,5.87,8.61,9,3.53s-1.17,15.67-1.17,16.84,3.91,16.84,3.91,16.84l3.92,8.62,6.66,3.52s10.42,5.09,15.27,11.36c9.4,12.14,27,68.93,58.36,71.67" />
            <line x1="61.25" y1="5.57" x2="61.87" y2="99.62" />
            <path d="M96.93,166.08c-5.09-8.82-6.86-15.87-7.35-20.71" />
            <path d="M85.85,89.39C85.27,44.76,91.05.8,91.05.8" />
            <path d="M250.07,486.84c-30.55-6.26-47.79-54-70.9-72.45-7-7.05-17.23-13.71-17.23-13.71-10.57-16.45-6.66-35.25-6.66-35.25l-4.7-3.92c-5.09-2.35-10.57-10.57-10.57-10.57l-2.35-4.31-.78-7.44c-9.4-25.07-25.46-118.67-25.46-118.67s11.75-18,15.66-23.11l2.92-8.19" />
            <path d="M117,125a40.78,40.78,0,0,0,3.43,15.64,21.37,21.37,0,0,0,17.23,5.48s.71,7.67,4.18,10.75" />
            <path d="M128.26,1.27A433.53,433.53,0,0,0,118,75.82" />
            <path d="M146.94,196.17l-.66,18.47s-3.14,18-2.75,24.68l3.53,45s7.44,40.73,12.92,55.22c0,0,3.14,11.75,9.4,15.27l6.27,2.75s-2.35,15.66,5.09,30.15c0,0,26.63,16.85,39.17,28.59s55.61,63.85,77.15,42.3c0,0,63.89-39.5,79.43-95.6,13.24-47.76-8.48-92-14-152.71l-36-14.44-22.87-11-15.53-9.32L277.35,166s-6.05-5.56-8.31-9.23" />
            <path d="M329.57,431.23c-25.46,9.79-47.78-12.14-67-23.11s-61.49-28.59-61.49-28.59a29.39,29.39,0,0,1-8.62-9c-4.31-10.18-1.17-17.23-1.17-17.23C187,352.11,180,345.85,180,345.85l-.79-6.27c-.39-4.7-3.91-18.8-4.3-29A109.67,109.67,0,0,1,176,291c9.79-16.45,5.88-41.12,5.88-41.12-2-8.22-2.75-26.24-3.14-26.63a141.72,141.72,0,0,0-6.64-23.42" />
            <path d="M198.93,196.14l25.68,54.14L236,276.52s-41.91,25.07-39.56,53.66c-2.35.39-1.57,15.27-1.57,15.27l1.57,3.14,5.87,1.17s-4.31,13.32,8.23,16.84,63.45,14.89,91.25,27.81,58.36,12.53,80.29-11.36" />
            <path d="M216.9,356s68,3.92,88,7.44,68.17,5.88,89.71-5.87" />
            <path d="M383,266.36c-32.51,14.49-35.79,16.82-110.21,36.4s-61.49,44.65-61.49,44.65" />
            <path d="M124.73,477.44c22.33,27.42,83,25.46,109.67,16.06s103-53.66,106.92-126.9S278.26,235,278.26,235L253.2,205.63l-20.14-24" />
            <path d="M36.61,274.56c14.49-11.75,19.49-23.1,58-23.1,28.2,0,57.64,9.85,87.73,15.66,31.4,6.07,53.66,9.4,53.66,9.4s30.3-4.15,59.92-16.84c28.78-12.32,56.74-32.94,66.58-49.35l15.92-8.49" />
            <path d="M122.75,185c7.92,6.76,44.07,21.91,78.54,10.42a193.42,193.42,0,0,0,54.5-28.17c12-8.8,24.79-20.49,25.49-26.71,0,0-19.18-13.8-78.53-6.31C143.63,141.66,124.75,171.3,122.75,185Z" />
            <path d="M165.51,101.55a37.36,37.36,0,0,0-.25,15.86s-1.69,7.62,9.32,10.16c0,0-1.51,7.87-1.08,12.94" />
            <path d="M185,2.11s-11.86,49.8-14.69,58.83" />
            <path d="M278.47,10.3s-8.62,39.94-23,58.58" />
            <path d="M236.09,100.24a25.36,25.36,0,0,0-4.48,6.72s-4,5.08-.57,12.42a29.47,29.47,0,0,0-7.9,11.58,6.86,6.86,0,0,0-2,5.08" />
            <path d="M372.49,23.67S343.9,80.92,314.54,98.42" />
            <path d="M178.38,155.45s46.49-9.69,75.78-16.51c2.18-.51,12.46-4.31,12.46-4.31a30.48,30.48,0,0,1,12.7-9.88c9.32-3.39,20.44-14.19,20.44-14.19" />
            <path d="M280.24,144.08l6.42-.14s25.41,5.37,44,.85,37.66-16.37,44.72-20.33" />
            <path d="M96.66,166.25s0,16.09,2.54,24.84,2.42,15.09,12.22,29.43,69.2,36.44,113.19,29.76,78.71-24.74,103-54.39c0,0,16.09-27.1,3.1-51.1-.28,0-6.77-17.79-37.54-28.51s-76.23-16.66-127.9,1.13C109.18,136.71,96.66,166.25,96.66,166.25Z" />
            <path d="M121.74,168.6s-10.24,18.08,6.48,28.32,33.93,21.82,74.53,16.7,86.87-47.29,86.87-69.13-77.9-30.08-119.28-13.56C134.17,145.37,129.93,157,121.74,168.6Z" />
            <path d="M110.82,167.92s-16.71,60.42,70.28,68.24c83.59,7.5,146-60,129.31-90.42s-91.78-38.55-141.25-20.47S110.82,167.92,110.82,167.92Z" />
            <path d="M293.83,85.72s87.84.57,68.64,124.61" />
            <path d="M.22,100.68s33.37,16.11,86.3-14.39c0,0,35.67-30.49,88.6-40.27s139.81-15.54,179.51,8.63c0,0,14.46,11.08,20.79,20.86" />
            <path d="M236,276.52s60.11,36,68.93,87c8.88,51.35-75.69,172.74-179.83,115.21" />
            <path d="M45.09,296.3s20.32-14.46,35.19-15.86,164.46-2.56,190.35,80.29c23.74,76-134.06,158.8-176.06,81.13" />
            <path d="M52,317s4.43-5.88,33.57-10.13S202.16,302.05,235,340s-18.41,92.06-64.44,101.26-65,2.3-78.82-15" />
            <path d="M57.18,334.27s13.23-5.18,19.56-4,23-1.15,34.52-3.45,94.36-8.63,111.62,18.41-23,57.82-43.71,69.19-38,7.91-50.07,9.06-25.32,3.45-42-9.78" />
            <path d="M111.42,220.52s-43.08-.4-59.53,8.22" />
            <path d="M109.85,188.79s-34.46,11.75-58,9.4" />
            <path d="M127.08,174.3l-5.34-5.7-10.92-.68-14.16-1.67a122.64,122.64,0,0,1-56.52,11.18" />
            <path d="M64.26,145.37S45.23,152,31.13,150" />
            <path d="M214.82,346.63s7.44,12.14-14.89,32.9-38,31.33-55.61,34.07-40.74,10.19-58.75-5.09" />
            <path d="M210.51,349c-.39,1.17-28.2,49-101.64,56.59,0,0-23.3,2.16-37-17.42" />
            <path d="M211.29,345.45c-11.75-4.7-49.35-.78-61.49.4s-35.25,4.3-35.25,4.3l-20.66,8.78-15-3.29-9-1.18-11.36-.39" />
            <path d="M103.19,369s57.19-13.7,64.24-14.49,43.08-7.83,43.08-7.83" />
            <path d="M76.17,380.7s10.18,14.1,53.66,3.92,81.46-37.21,81.46-37.21c46.22-14.49,92-16.84,124.94-20.36S388.92,316,388.92,316" />
            <path d="M159.2,496.24c-18-9.4-34.07-32.5-39.95-52.48s-14.88-27-14.88-27c-22.33-2.35-14.1-42.69-14.1-42.69s-6.66-3.92-11-13.71l-.39-4.7s-2.74-33.29-6.66-47S57,245.58,59.33,198.58a150.28,150.28,0,0,0-14.49-29.37C35.44,154.72,12.43,74.08,7,16.51" />
            <path d="M211.29,347.41s-63.84,22.33-102.22,26.24" />
            <path d="M219.4,136.71a38.75,38.75,0,1,1-72.33,25.23" />
            <path d="M248.89,164.66c-49.69-2.4-70.51-9.21-70.51-9.21s5.94,12.69,28.24,33.34" />
            <path d="M176.56,192.69c-1.49-26.92,1.82-37.24,1.82-37.24a197.54,197.54,0,0,1,24.37-17.1" />
            <path d="M165.42,149.9c9.55,4.15,13,5.55,13,5.55a187,187,0,0,0-26.14,19.81" />
            <path d="M190,140.51a18.34,18.34,0,1,1-28.23,12.14" />
            <path d="M180.08,145.72c-.27,2.15-1.91,6.15-1.7,9.73" />
            <path d="M.22,20.71C83.43-7.46,163.7-1.42,260.76,10.3c57,6.88,57.07,4.91,174.64,29" />
            <path d="M392.24,27.18s11.65,204.27,20.9,274.49,21.86,169,41.62,206.38c0,0,17.77,7.8,23.17-14.23C509.41,365.35,476,187.34,480,203.71" />
            <path d="M387.07,270.56c17.66.84,22.19-4.2,22.19-4.2,36.78-4.69,81.85-22.71,81.85-22.71" />
            <path d="M430.38,416s-14.89,10.39-21.87-.85" />
            <path d="M454.76,508.05s-6.86-5.05-15.43-11.63" />
            <path d="M398.1,121.46s-9.59,4.51-19.71,1.88" />
          </g>
        </g>
      </svg>
    );
  }

}

export default Wire;