
import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Isologo from './isologo';
import Logo from './logo';

class Header extends React.Component {

	static $bodyHtml = $('body, html');
	static $body = $('body');
	/**
	 * ultima posision del scroll
	 */
	 static lastScrollTop = 0;
	 /**
	 * Delta  of header
	 */
	static delta = 5;

	componentDidMount() {
		$(window).scroll(() => {
			this.headerFixed();
		});

		this.enabledMenuMobile();
		this.disabledMenuMobile()
		this.GoSection('.btn-services', '#services');
		this.GoSection('.btn-clients', '#clients');
		this.detectDevice();
		this.changeOrientation();
		this.showPop();
		window.addEventListener("orientationchange", this.changeOrientation, false);
	}

	/*-------------------------------------------------------------------------------*/
	// Menu Mobile
	/*-------------------------------------------------------------------------------*/

	enabledMenuMobile(){
		$('.sirio-menu-lines').click(function(){
			if(Header.$body.hasClass('enabled-navigation')){
				Header.$body.toggleClass('default-navigation');
			}
			Header.$body.toggleClass('body-block');
			Header.$body.addClass('enabled-navigation');
		});
	}

	disabledMenuMobile() {
		$('.sirio-navigation a').click(function(){
			Header.$body.addClass('default-navigation');
			Header.$body.removeClass('body-block');
		});
	}

	/*-------------------------------------------------------------------------------*/
	// Header Fixed
	/*-------------------------------------------------------------------------------*/
	
	headerFixed() {
		let $elements = $('[data-role="toogleFix"]');
		let pointActive = 100;
		let nowScrollTop = $(window).scrollTop();
		let keyAction = $(document).scrollTop() > pointActive;

		if(keyAction) {
			$elements.addClass("fix");
		} else {
			$elements.removeClass("fix");
		}

		if(Math.abs(Header.lastScrollTop - nowScrollTop) >= Header.delta){
			if (nowScrollTop > Header.lastScrollTop){
				// downscroll code
				if(keyAction){
					if($elements.hasClass('showfix')){
						$elements.addClass('hidefix');
					}
					$elements.removeClass('showfix');
				}
			} else {
				//upscroll code
				if(keyAction){
					$elements.addClass('showfix');
					$elements.removeClass('hidefix');
				}else if($(document).scrollTop() < 10){
					$elements.removeClass('showfix');
				}
			}
			Header.lastScrollTop = nowScrollTop; 
		}
	}

	/*-------------------------------------------------------------------------------*/
	// Go Section Animation
	/*-------------------------------------------------------------------------------*/

	GoSectionHash(){
		let URLhash = window.location.hash;
		let hHeader = 60;
		if(URLhash){
			let scrollTopSection = $(URLhash).offset().top;
			Header.$bodyHtml.animate({scrollTop:(scrollTopSection - hHeader)}, 'fast');
		}
	}

  	GoSection(btn, section) {
		if ($(section).length) {
			let hHeader = 60;
			let scrollTopSection = $(section).offset().top;
			$(btn).click(function () {
				Header.$bodyHtml.animate({ scrollTop: (scrollTopSection - hHeader) }, 'fast');
			});
		}
	}

  	/*--------------------------------------------------------------------------------*/
	//*IDENTIFICA SI ES UN DISPOSITIVO DE ESCRITORIO*//
	/*--------------------------------------------------------------------------------*/
	detectDevice() {
		let is_android = navigator.userAgent.match(/Android/i);
		let mobileDetectIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
		let isIPadPro = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

		if (!mobileDetectIOS && !is_android && !isIPadPro) {
			Header.$bodyHtml.addClass('desktop');
		} else {
			Header.$bodyHtml.addClass('touch');
		}

		//if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) this.$bodyHtml.addClass('safari OS' + navigator.appVersion.substring(27, 29));
	}

	/*-------------------------------------------------------------------------------*/
	// Detstar horientacion del dispositivo
	/*-------------------------------------------------------------------------------*/

	changeOrientation() {
		if(window.orientation == 90 || window.orientation == -90 ) {
			$('body').addClass('landscape');
			$('body').removeClass('portrait');
		} else {
			$('body').addClass('portrait');
			$('body').removeClass('landscape');
		};
	}

	/*-------------------------------------------------------------------------------*/
	// Show Pop
	/*-------------------------------------------------------------------------------*/

	showPop(){
		$(document).on("click", '.btn-quote' , function() {
			let service = $(this).attr('rel');
			$('.pop-quote').addClass('enabled-quote');
			$('header').addClass('disable-fixed');
			$('input:checkbox').prop('checked', false).change();
			$('#'+service+'-pop').prop('checked', true).change();
		});
		
		$('.pop-quote .close').click(function(){
			$('.pop-quote').removeClass('enabled-quote');
			$('header').removeClass('disable-fixed');
		});
	}

	render() {
		return (
			<header data-role="toogleFix">
				<div className="container-fluid h-100">
					<div className="row align-items-center h-100">
						<div className="col-auto mr-auto d-flex d-md-none z2">
							<div className="sirio-menu-lines d-flex flex-column">
								<div className="line-top d-flex mb-auto"></div>
								<div className="line-bottom d-flex mt-auto"></div>
							</div>
						</div>
						<div className="col-auto content-menu z2">
							<Link className='not-decoration' to="/">
								<Isologo customClass="d-block d-md-none" />
								<Logo />
							</Link>
						</div>
						<div className="col-auto d-block d-md-none ml-auto z2">
							<a className="d-flex justify-content-end scale-t2 text-white phone-mobile" href="https://wa.me/573132572108" target={ '_blank'}>
								<i></i>
							</a>
						</div>
						<div className="content-sirio-navigation d-flex flex-column d-lg-block col-12 col-md ml-auto mr-auto z1">
							<nav className="sirio-navigation row d-flex align-items-center justify-content-center mt-4 mt-md-0">
								<div className="col-12 col-md-auto">
									<Link to="/#services" className="btn-services">Servicios</Link>
								</div>
								<div className="col-12 col-md-auto">
									<Link to={{
										pathname: "/projects",
										state: { fromDashboard: true }
									}}>
										Proyectos
									</Link>
								</div>
								<div className="col-12 col-md-auto">
									<Link to="/#clients" className="btn-clients">Clientes</Link>
								</div>
								<div className="col-12 col-md-auto | btn-quote">
									<a className='btn-quote'>Contacto</a>
								</div>
							</nav>
							<div className='social-navigation | d-lg-none mt-auto mb-5'>
								<div className="mb-4">
									<a href="mailto:siriostudiosdesign@gmail.com" className='text-secondary'>siriostudiosdesign@gmail.com</a>
								</div>
								<div className="scale-t2 | d-flex | gap-5">
									<a target="_blank" href="https://www.behance.net/siriostudiosdesign/">
										<i className="fab fa-behance"></i>
									</a>
									<a target="_blank" href="https://www.artstation.com/siriostudiosdesign/">
										<i className="fab fa-artstation"></i>
									</a>
									<a target="_blank" href="https://www.facebook.com/siriostudiosdesign/">
										<i className="fab fa-facebook"></i>
									</a>
									<a target="_blank" href="https://www.instagram.com/siriostudiosdesign/">
										<i className="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-auto content-phone text-center d-none d-md-block z2 | btn-quote | pointer">
							<div className="region">Bogotá, Colombia</div>
							<b className="phone | d-block">(+57) 313.257.2108</b>
							<b className="get-in-touch">¡Ponte en contacto!</b>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;

