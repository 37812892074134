import React from 'react'
import SliderInside from '../components/slider_inside'
import OtherProjects from '../components/other_projects'
import BoxProject from '../components/box_project'

// Assets
import protegemosImage1 from '../assets/images/projects/protegemos/Protegemos_01.webp'
import protegemosImage2 from '../assets/images/projects/protegemos/Protegemos_02.webp'
import protegemosImage3 from '../assets/images/projects/protegemos/Protegemos_03.webp'
import protegemosImage4 from '../assets/images/projects/protegemos/Protegemos_04.webp'
import protegemosImage5 from '../assets/images/projects/protegemos/Protegemos_05.webp'
import protegemosImage6 from '../assets/images/projects/protegemos/Protegemos_06.webp'
import protegemosImage7 from '../assets/images/projects/protegemos/Protegemos_07.webp'
import protegemosImage8 from '../assets/images/projects/protegemos/Protegemos_08.webp'
import protegemosImage9 from '../assets/images/projects/protegemos/Protegemos_09.webp'


class Protegemos extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<>
				<SliderInside
					service="web"
					project="protegemos"
					name="Protegemos Seguridad"
					description="
					<b class='badge badge--web'>UX UI</b>
					<b class='badge badge--branding'>Marca</b>
					<b class='badge badge--system'>Design System</b>
					<b class='badge badge--layout'>Maquetación</b>
					"
				/>

				<section className="container-fluid bg-overlay-site position-relative z2">
					<div className="row justify-content-center services-gallery">
						<BoxProject
							elementType="image"
							elementSrc={protegemosImage1}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={protegemosImage2}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={protegemosImage3}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={protegemosImage4}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={protegemosImage5}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={protegemosImage6}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={protegemosImage7}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={protegemosImage8}
							layout="col-12 none-info"
						/>

						<BoxProject
							elementType="image"
							elementSrc={protegemosImage9}
							layout="col-12 none-info"
						/>
					</div>

					<div className="col-12">
						<div className="d-flex justify-content-center box-btn mt-4 pb-3 pb-md-4">
							<a className="buttom btn-quote" rel="model">Cotiza tu Proyecto</a>
						</div>
					</div>

					<OtherProjects
						type='web'
						noPrint='protegemos'
					/>

				</section>
			</>
		);
	}
}

export default Protegemos;