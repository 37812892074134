import React from 'react'
import SliderInside from '../components/slider_inside'
import OtherProjects from '../components/other_projects'
import BoxProject from '../components/box_project'

// Assets
import Render1 from '../assets/images/projects/creature-machine/3D-animation-sirio-03.webp'
import Render2 from '../assets/images/projects/creature-machine/3D-animation-sirio-05.webp'
import Render3 from '../assets/images/projects/creature-machine/3D-effects.webp'
import Render4 from '../assets/images/projects/creature-machine/3D-animation-sirio-02.webp'



class CreatureMachine extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<>
				<SliderInside
					service="3d"
					project="creature-machine"
					name="CREATURE MACHINE"
					description="
					<b class='badge badge--concept'>Concept Art</b>
					<b class='badge badge--3d'>3D</b>
					<b class='badge badge--animation'>Animacion</b>
					"
				/>

				<section className="container-fluid bg-overlay-site position-relative z2">
					<div className="row justify-content-center services-gallery">
						<BoxProject
							elementType="image"
							elementSrc={Render1}
							title="MODELADO 3D"
							description="Personaje high poly"
							layout="col-12 mb-4"
						/>

						<BoxProject
							elementType="image"
							elementSrc={Render2}
							title="RIG"
							description="Personaje high poly"
							layout="col-12 col-lg-6"
						/>

						<BoxProject
							elementType="image"
							elementSrc={Render3}
							title="COMPOSICIÓN DE NODOS"
							description="Efectos y corrección de color"
							layout="col-12 col-lg-6"
						/>

						<BoxProject
							elementType="image"
							elementSrc={Render4}
							title="RENDER FINAL"
							description="Personaje high poly"
							layout="col-12 mt-4 mb-4"
						/>
					</div>

					<div className="col-12">
						<div className="d-flex justify-content-center box-btn mt-4 pb-3 pb-md-4">
							<a className="buttom btn-quote" rel="model">Cotiza tu Proyecto</a>
						</div>
					</div>

					<OtherProjects
						type='3d'
						noPrint='robot'
					/>
				</section>
			</>
		);
	}

}

export default CreatureMachine;