import React from 'react';
import { Link } from 'react-router-dom';
import Branding from '../components/icons/branding';
import Cube from '../components/icons/cube';
import Web from '../components/icons/web';

class SliderInside extends React.Component {
  render() {
    return (
      <>
      <div className={`content-slider-inside slider-${this.props.project}`}>
          <div className="slider-inside d-flex justify-content-center">
            <div className="image-slide"></div>
            <div className="row align-items-center justify-content-center m-0 position-relative z3 wrapper-slider Animation mt-auto mb-auto">
              <div className="col-12 text-center">
                <div className="mb-3">
                  {this.props.service === "3d" && <Cube />}
                  {this.props.service === "branding" && <Branding />}
                  {this.props.service === "web" && <Web />}
                </div>
                <div className="text-center">
                  <h3 className='mb-3'>{this.props.name}</h3>
                </div>
                <div className='d-flex flex-wrap gap-2 justify-content-center' dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
                <div className="mt-4 mb-3">
                  <Link className='d-inline-flex align-items-center text-white' to="projects"><i className="fas fa-angle-left mr-2 scale-t1"></i> Volver a los Proyectos</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

}

export default SliderInside;