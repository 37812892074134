import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/skin.css";
// Templates
import Layout from "./Layout";
// Components
import Home from "./pages/home";
import Projects from "./pages/projects";
import WakeUp from "./pages/wake_up";
import FendiChateau from "./pages/fendi_chateau";
import MillionAndUp from "./pages/million_and_up";
import Hontus from "./pages/hontus";
import ZNetworks from "./pages/znetworks";
import NotFound from "./pages/NotFound";
import Miles from "./pages/miles_car_rental";
import Nohelia from "./pages/cafe_de_nohelia";
import CreatureMachine from "./pages/creature_machine";
import Zadoc from "./pages/zadoc";
import Viajemos from "./pages/viajemos";
import Protegemos from "./pages/protegemos";

//const $ = require('jquery');
//require('../src/assets/js/scripts.js');

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/index.html" component={Home} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/wake-up" component={WakeUp} />
          <Route exact path="/fendi-chateau" component={FendiChateau} />
          <Route exact path="/million-and-up" component={MillionAndUp} />
          <Route exact path="/hontus" component={Hontus} />
          <Route exact path="/znetworks" component={ZNetworks} />
          <Route exact path="/miles-car-rental" component={Miles} />
          <Route exact path="/cafe-de-nohelia" component={Nohelia} />
          <Route exact path="/creature-machine" component={CreatureMachine} />
          <Route exact path="/zadoc" component={Zadoc} />
          <Route exact path="/viajemos" component={Viajemos} />
          <Route exact path="/protegemos-seguridad" component={Protegemos} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
